import React from "react";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";

const PlayerModal = ({ open, toggleModal, url }) => {
  return (
    <Modal
      open={open}
      onClose={toggleModal}
      styles={{
        modal: {
          maxWidth: "unset",
          width: "70%",
          height: "70%",
          padding: "unset",
          border: 0
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)"
        },
        closeButton: {
          background: "#EDE5E1"
        }
      }}
      center
    >
      <ReactPlayer
        url={url}
        controls={true}
        width="100%"
        height="100%"//"calc(100vh - 400px)"
      />
    </Modal>
  )
}

export default PlayerModal
