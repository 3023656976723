import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  city,
  currentYear,
  ibanAccounts,
  localAddress,
  romanianTexts,
} from "./utils/constants";

const Footer = ({ isAuthenticated }) => (
  <div className="footer">
    <div className="row">
      <div className="col-sm simple-component">
        {/* <p className="footer-social-title">CONECTAȚI-VĂ</p> */}
        <div className="footer-social-links">
          <a
            href="https://www.facebook.com/betelsighet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-2x fa-facebook-square" />
          </a>
          {/* <a href="https://twitter.com/betelsighet" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" /></a> */}
          <a
            href="https://www.youtube.com/channel/UCMN5bRpid3A09cIrkz97H2A"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-2x fa-youtube" />
          </a>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="column left">
        <div className="footer-links">
          <Link to="/about">Despre noi</Link>
          <span className="footer-link-slash">|</span>
          {/* <Link to="/ministries">Program</Link><span className="footer-link-slash">|</span> */}
          {/* <Link to="/sermons">Sermons</Link><span className="footer-link-slash">|</span> */}
          <Link to="/live">LIVE</Link>
          <span className="footer-link-slash">|</span>
          <a href="/autoinstalator/piwik" target="_blank">
            Matomo
          </a>
          {isAuthenticated ? (
            ""
          ) : (
            <>
              <span className="footer-link-slash">|</span>
              <Link to="/login">Login</Link>
            </>
          )}
        </div>
        <div className="col-sm on-left-row-components">
          <img className="icon" src="/logos/localization.png" alt="" />
          <p>
            {romanianTexts.fullChurchName}
            <br />
            {localAddress}, {city}
          </p>
        </div>
      </div>
      <div className="column middle">
        <div className="iban-component">{ibanAccounts}</div>
      </div>
      <div className="column right">
        <div className="social-subscribe-wrapper">
          <div
            className="g-ytsubscribe"
            data-channelid="UCMN5bRpid3A09cIrkz97H2A"
            data-layout="default"
            data-count="hidden"
          ></div>
          <div
            className="fb-like"
            data-href="https://www.facebook.com/betelsighet"
            data-width="100"
            data-layout="box_count"
            data-action="like"
            data-size="large"
            data-share="true"
          ></div>
        </div>
        {/* <div className="col-sm d-none d-sm-none d-md-none d-lg-block"> */}
        <img
          alt="footer-logo"
          srcSet="/logos/logo_footer@2x.png 2x"
          className="footer-logo float-right"
          src="/logos/logo_footer.svg"
        />
        {/* </div> */}
      </div>
    </div>
    <div className="row">
      <div className="copyright-component">
        <p>
          {romanianTexts.logoChurchName} &copy; COPYRIGHT {currentYear} - ALL
          RIGHTS RESERVED
        </p>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid,
});

export default connect(mapStateToProps)(Footer);
