import React from 'react';
import PageFragment from '../page/PageFragment';
import BibleVerse from '../utils/BibleVerse';

const HomePageOurBelief = () => {
  return (
    <>
      <PageFragment
        title="BAZA CREDINȚEI NOASTRE"
        // text="Credința noastră se bazează pe următoarele argumentele:"
        text={(
          <>
            <h1>- BIBLIA -</h1>
            <hr />
            <p>Biblia sau Sfânta Scriptură este Cuvantul lui Dumnezeu, care pentru noi este singura autoritate și normă în viața noastră de credință: <BibleVerse reference="2TI.3.16-17" text="„Toată Scriptura este insuflată de Dumnezeu și de folos ca să înveţe, să mustre, să îndrepte, să dea înţelepciune în neprihănire, pentru ca omul lui Dumnezeu să fie desăvârșit și cu totul destoinic pentru orice lucrare bună.” (2 Timotei 3:16-17)" /></p>
            <p>Biblia ne arată că omul poate fi mântuit doar prin Isus Hristos: <BibleVerse reference="ACT.4.12" text="„În nimeni altul nu este mântuire, căci nu este sub cer niciun alt Nume dat oamenilor în care trebuie să fim mântuiţi.” (Faptele Apostolilor 4:12)" /> și că mântuirea se primește prin har, nu prin fapte: <BibleVerse reference="EPH.2.8-9" text="„Căci prin har aţi fost mântuiţi, prin credinţă. Și aceasta nu vine de la voi, ci este darul lui Dumnezeu. Nu prin fapte, ca să nu se laude nimeni.” (Efeseni 2:8-9)" /></p>
            <p>Biblia ne învață că omul, pentru a avea o relație cu Dumnezeu are nevoie de:</p>
          </>
        )}
      />

      {/* </div> */}
      <div className="row">
        <div className="col-md homepage-text brown-background">
          <h3>POCĂINȚĂ</h3>
          <hr />
          <p>Pocăința presupune o schimbare a minții ce implică o schimbare a concepției cu privire la păcat, cu privire la Dumnezeu și cu privire la sine. Prin pocăință omul se întoarce la Dumnezeu, îi pare rău pentru păcatele făcute, se roagă să îi fie iertate și caută să nu mai trăiască în păcat. <BibleVerse reference="ACT.3.19" text="„Pocăiţi-vă dar și întoarceţi-vă la Dumnezeu, pentru ca să vi se șteargă păcatele, ca să vină de la Domnul vremurile de înviorare” (Faptele Apostolilor 3:19)" /></p>
        </div>
        <div className="col-md homepage-text dark-grey-background">
          <h3>NAȘTEREA DIN NOU</h3>
          <hr />
          <p>Nașterea din nou este lucrarea lui Dumnezeu în viața omului, prin Cuvântul Scripturii ( <BibleVerse reference="JAS.1.18" text="„El, de bunăvoia Lui, ne-a născut prin Cuvântul adevărului...” Iacov 1:18" /> ) și prin Duhul Sfânt care face înnoirea vieții lui ( <BibleVerse reference="Tit.3.5" text="„El ne-a mântuit nu pentru faptele făcute de noi în neprihănire, ci pentru îndurarea Lui, prin spălarea nașterii din nou și prin înnoirea făcută de Duhul Sfânt...” Tit 3:5" /> ).</p>
          <p></p>
        </div>
      </div>
      <div className="row">
        <div className="col-md homepage-text light-blue-background">
          <h3>BOTEZUL ÎN APĂ</h3>
          <hr />
          <p>Botezul în apă are origine divină, se administrează persoanelor care s-au întors la Dumnezeu și cred în jertfa Domnului Isus Hristos și se face prin scufundare în apă în Numele Tatălui, al Fiului și al Duhului Sfânt: <BibleVerse reference="MAT.28.19" text="„Duceţi-vă și faceţi ucenici din toate neamurile, botezându-i în Numele Tatălui și al Fiului și al Sfântului Duh.” (Matei 28:19)" /></p>
        </div>
        <div className="col-md homepage-text khaki-background">
          <h3>BOTEZUL CU DUHUL SFÂNT</h3>
          <hr />
          <p>Botezul cu Duhul Sfânt este o făgăduință a lui Dumnezeu dată ucenicilor Săi și credincioșilor din toate veacurile. <BibleVerse reference="ACT.2.39" text="„Căci făgăduinţa aceasta este pentru voi, pentru copiii voștri și pentru toţi cei ce sunt departe acum, în oricât de mare număr îi va chema Domnul Dumnezeul nostru...” (Faptele Apostolilor 2:39)" /></p>
        </div>
        <div className="col-md homepage-text beige-background">
          <h3>CINA DOMNULUI</h3>
          <hr />
          <p>Cina Domnului este o lucrare insitituită de Domnul Isus Hristos, care a fost practicată de ucenicii Lui și pe care toți credincioșii trebuie să o practice. <BibleVerse reference="1Cor.11.26" text="„Pentru că, ori de câte ori mâncaţi din pâinea aceasta și beţi din paharul acesta, vestiţi moartea Domnului până va veni El.” (1 Corinteni 11:26)" /></p>
          {/* <p>Făgăduinţa botezului cu Duhul Sfânt este pentru toţi credincioşii, iar lucrarea Duhului Sfânt se manifestă în cele nouă daruri <BibleVerse reference="1COR.12.4-11" text="(1 Corinteni 12:4-11)" />.</p>
          <p><BibleVerse reference="ACT.2.38-39" text="„„Pocăiţi-vă ”, le-a zis Petru, „și fiecare din voi să fie botezat în Numele lui Isus Hristos, spre iertarea păcatelor voastre; apoi veţi primi darul Sfântului Duh. Căci făgăduinţa aceasta este pentru voi, pentru copiii voștri și pentru toţi cei ce sunt departe acum, în oricât de mare număr îi va chema Domnul Dumnezeul nostru.”” (Faptele Apostolilor 2:39)" /></p> */}
        </div>
      </div>
    </>
  );
}

export default HomePageOurBelief;
