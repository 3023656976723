import React from 'react';
import PageFragment from '../page/PageFragment';

const HomePageMeetingTimes = () => {
  return (
    <PageFragment 
      title="ÎNTÂLNIRILE NOASTRE PRINCIPALE SUNT DUMINICA ÎNCEPÂND CU ORELE 09.00 &amp; 17.00"
      text="În timpul programelor de dimineață avem o școală duminicală pentru copii, în care desfășurăm programe speciale pentru ei."
      buttonText="Mai multe detalii"
      buttonUrl="/ministries"
    />
  );
}

export default HomePageMeetingTimes;
