import React from 'react';

const BeliefSection = () => {

  return (
    <div className="story">
      <p>Sursa: <a href="../media/Marturisirea-de-credinta.pdf" target="_blank">Mărturisirea de Credinţă a Cultului Penticostal din România </a></p>
      
      <h2>Cuvânt înainte</h2>
      
      <p>
        Biserica lui Dumnezeu Apostolică Penticostală din România a avut încă de la organizarea ei o Mărturisire de Credinţă al cărei temei este Cuvântul lui Dumnezeu.
      </p>
      <p>
        Această Mărturisire de Credinţă stă la baza Statutului de organizare şi funcţionare a Cultului Creştin Penticostal — Biserica lui Dumnezeu Apostolică din România.
      </p>

      <hr />

      <h4>
        1. Noi credem că Biblia sau Sfânta Scriptură este Cuvântul lui Dumnezeu fără greşeală şi singura noastră normă în viaţa de credinţă
      </h4>
      <p>
        Biblia sau Sfânta Scriptură, compusă din Vechiul Testament şi Noul Testament, este insuflată de Dumnezeu (2 Tim. 3:16; 2 Petru 1:20-21).
        </p>
      <p>
        Biblia este o descoperire dumnezeiască făcută oamenilor. Ea a fost scrisă în diferite perioade de timp, până la finalizarea ei, prin cartea Apocalipsei. Nimeni nu are dreptul să adauge, să scoată sau să schimbe ceva din ea (Prov. 30:6; Gal. 1:8-9; Apoc. 22:18-19). Biblia este Cuvântul lui Dumnezeu, viu şi lucrător, mai tăietor ca o sabie cu două tăişuri (Evrei 4:12).
        </p>
      <p>
        Vechiul Testament ne arată cum Dumnezeu a pregătit venirea în lume a lui Mesia, care este Domnul Isus Hristos (1 Cor. 10:6; Gal. 3:24; Col. 2:14-17; Efes. 2:14-16; Evrei 8:13).
        </p>
      <p>
        Noul Testament ne arată viaţa şi învăţătura Domnului Isus Hristos care stau la temelia Bisericii lui Dumnezeu. De asemenea, Noul Testament cuprinde învăţătura apostolilor (Rom. 10:4; 3:24; 1 Cor. 15:12; 2 Tim. 1:13; Romani 1:16; 2 Tes. 1:8-9).
      </p>

      <h4>
        2. Noi credem într-un Dumnezeu în trei persoane: Tatăl, Fiul şi Duhul Sfânt
      </h4>

      <h5>a. Dumnezeu Tatăl</h5>
      <p>
        S-a descoperit în vechime slujitorilor Săi ca un Dumnezeu care nu depinde de cineva, zicând: „Eu sunt Cel ce sunt" (Exod 3:14).
      </p>
      <p>
        Dumnezeu este Duh (Ioan 4:24), viaţă (1 Tim. 6:13-16; Fapte 17:25-28), lumină (1 Ioan 1:5), iubire (1 Ioan 4:8), înţelepciune (Ieremia 10:12), dreptate şi sfinţenie (Apoc. 15:3 şi 4:8).
      </p>
      <p>
        Dumnezeu este viu şi veşnic (Ps. 84:2; Deut. 33:27; Isaia 40:28), El este bun (Marcu 10:18), desăvârşit (Matei 5:48), îndurător (Deut. 4:31; Iacov 5:11), credincios (2 Tim. 2:13), îndelung răbdător (Rom. 2:4). El nu poate fi văzut, nici pătruns de mintea omenească (1 Ioan 4:12; Iov 11:7-9). El poate fi cunoscut prin Isus Hristos, prin Duhul Sfânt, prin Sfânta Scriptură (1 Ioan 5:20; 1 Cor. 2:10-11), prin creaţiune (Rom. 1:19-20; Isaia 40:26) şi prin purtarea Sa de grijă pe care o are faţă de noi (1 Petru 5:7).
      </p>

      <h5>b. Dumnezeu Fiul</h5>
      <p>Domnul nostru Isus Hristos este veşnic (Evrei 13:8). Există din veşnicie (Ioan 17:5) şi este Unicul Fiu născut din Tatăl, „Cel întâi născut din toată zidirea " înaintea oricărei făpturi (Coloseni 1:15-16,17; Ioan 1:2-3).
      </p>
      <p>
        El este adevăratul Mesia (Mântuitor) care a fost făgăduit, împlinitorul Legii şi al profeţiilor Vechiului Testament (Luca 2:26-27).
      </p>
      <p>
        El este Fiul lui Dumnezeu (Luca 1:32) şi totodată şi Fiul omului (Matei 9:6; Marcu 2:28). Prin Duhul Sfânt S-a întrupat şi S-a născut prin fecioara Maria (Luca 1:34-35), venind în lume ca om. El ni L-a descoperit pe Dumnezeu ca Tată (Mat. 5:16; Ioan 4:23). „Fiul lui Dumnezeu S-a arătat ca să nimicească lucrările diavolului" (1 Ioan 3:8).
      </p>
      <p>
        El a venit pe pământ: să ridice păcatele celor ce vor crede în El (Ioan 1:29), să sufere (Isaia 53:3-10; Matei 16:21), să moară pentru noi (Rom. 5:8), să ne împace cu Dumnezeu (Col. 1:20), să ne mântuiască (Ioan 12:47). Sângele lui Isus Hristos ne curăţeşte de orice păcat (1 Ioan 1:7). A înviat a treia zi după Scripturi (1 Cor. 15:4). S-a înălţat la cer şi a şezut la dreapta lui Dumnezeu (Marcu 16:19). El mijloceşte pentru credincioşi, pregătindu-le loc în cer (Ioan 14:3). El este Cel dintâi înviat dintre cei morţi (Col. 1:18). Prin înviere a primit un corp schimbat într-o stare de slavă (Ioan 20:12-20; Filipeni 3:21). El va veni a doua oară pentru ridicarea Bisericii Sale de pe pământ şi pentru judecata lumii (Matei 25:31-33).
      </p>
      <p>
        El este judecătorul celor vii şi al celor morţi (Fapte 10:42; Ioan 5:22).
      </p>

      <h5>c. Dumnezeu Duhul Sfânt</h5>
      <p>
        Duhul Sfânt, numit şi Duhul lui Dumnezeu şi Mângâietorul sau Duhul Adevărului (Ioan 14:26), este a treia persoană din Sfânta Treime (1 Cor. 12:3-6; 2 Cor 13:14). El a fost de la început, a conlucrat cu Tatăl şi cu Fiul la creaţiune (Genesa 1:2).
        </p>
      <p>
        Lucrarea şi natura Sa sunt absolut nepătrunse de mintea omenească, după cum de nepătruns este Dumnezeu însuşi. Prin Duhul lui Dumnezeu şi prin suflarea Sa, sunt făcuţi toţi oamenii. Prin El toate făpturile primesc viaţa (Iov 33:4).
        </p>
      <p>
        Oamenii lui Dumnezeu din vechime au vorbit şi au scris Cuvântul lui Dumnezeu, fiind „mânaţi de Duhul Sfânt" (2 Petru 1:21).
        </p>
      <p>
        La Cincizecime (Rusalii), Duhul Sfânt S-a pogorât asupra credincioşilor, după ce Domnul Isus îşi încheiase misiunea pe pământ şi Se înălţase la cer (Fapte 2:1-4). Ucenicii au fost umpluţi cu Duhul Sfânt şi din acea zi au fost însoţiţi de lucrări supranaturale: semne, minuni, vindecări şi felurite daruri (Evrei 2:4; Marcu 16:17-18). El este călăuzitorul Bisericii lui Dumnezeu nou-testamentale, din ziua Rusaliilor (Cincize-cimii) până la răpirea ei (Ioan 16:13).
        </p>
      <p>
        Duhul Sfânt astăzi alege (Fapte 13:2; 20:28), învaţă (Ioan 14:26), umple (Fapte 2:4; 4:31) îmbracă (Luca 24:49) şi îi trimite în lucrare pe slujitorii lui Dumnezeu (Fapte 10:19-20; 8:29).
        </p>
      <p>
        Prin Duhul Sfânt omul primeşte naşterea din nou (Ioan 3:5-8; Tit. 3:5), primeşte viaţa veşnică (Gal. 6:8), poate trăi după voia lui Dumnezeu (Rom. 8:6-10), înţelege Sfânta Scriptură şi lucrările lui Dumnezeu (1 Cor. 2:9-14).
        </p>
      <p>
        Dumnezeu dă darul Sfântului Duh celor care se pocăiesc, îl ascultă şi-L primesc pe Isus Hristos ca Mântuitorul lor personal (Fapte 2:38, 5:32; Ioan 7:37-39).
        </p>
      <p>
        Duhul Sfânt S-a înfăţişat sub forma unui porumbel (Mat. 3:16). Umplerea cu Duhul Sfânt a fost însoţită de-ma-nifestări fizice exterioare: limbi de foc, (Fapte 2:1-4), vânt (Fapte 2:2), cutremur (Fapte 4:31).
      </p>

      <h4>
        3. Noi credem că Dumnezeu la început a făcut cerurile şi pământul (Gen. 1:1)
      </h4>

      <h5>a. Cerurile</h5>
      <p>
        Acolo este scaunul de domnie al lui Dumnezeu (Ps. 103:19;
        </p>
      <p>
        Matei 5:34) şi locuinţa îngerilor (Matei 18:10). Cuvântul lui Dumnezeu ne arată că în al treilea cer este Raiul lui Dumnezeu (2 Cor. 12:1-4). Domnul Isus S-a înălţat la cer şi acolo pregăteşte un loc pentru credincioşii Săi (Ioan 14:2; Luca 24:50-51). în ceruri se află scrise numele celor mântuiţi (Luca 10:20; Ioan 17:24).
      </p>

      <h5>b. Pământul</h5>
      <p>
        La început Dumnezeu a făcut cerurile şi pământul (Gen.1:1), pământul era pustiu şi gol (Gen. 1:2). Dumnezeu prin puterea şi Cuvântul Său a făcut ca în decursul a 6 zile, pământul să fie bun de locuit şi 1-a dat în stăpânire omului spre a-1 umple, supune şi îngriji (Genesa 1:31; 26-28).
        </p>
      <p>
        După intrarea păcatului în lume, Dumnezeu a blestemat pământul (Gen. 3:17-18). Din cauza răutăţii omului Dumnezeu a adus judecată şi pedeapsă asupra pământului, ca: pustiirea prin potop (Gen. 6:11-13), nimicirea Sodomei şi a Gomorei (Gen. 19:12-14; 2 Petru 2:6) şi altele. De atunci încoace s-au mai petrecut diferite cataclisme, potrivit cu cele prezise de Domnul Isus (Mat. 24:7). La urmă, pământul existent va da loc noului pământ, unde va locui neprihănirea (2 Petru 3:7, Apoc. 21:1-4).
      </p>

      <h4>4. Noi credem că Dumnezeu a creat îngerii</h4>

      <h5>a. Îngerii</h5>
      <p>
        Îngerii sunt făpturi spirituale, create de Dumnezeu, fără păcat, puşi în slujba Sa (Gen. 19:15; Apoc. 19:9-10). Ei îndeplinesc şi diferite slujbe „pentru cei ce vor moşteni mântuirea " (Evr. 1:14; Luca 15:10; Fapte 10:4-6,27; 23:24 etc.)
        </p>
      <p>
        Îngerii sunt, totodată şi cei care duc la îndeplinire voinţa lui Dumnezeu executând hotărârea mâniei Lui în ce priveşte pedepsirea celor răi (Mat. 13:39-41,49).
        </p>
      <p>
        Sunt mai multe feluri de îngeri care poartă diferite nume, ca: Arhanghelul Mihail, Arhanghelul Gabriel, serafimii şi heruvimii etc. Fiecare categorie are altă însărcinare (Gen.3:24; Isaia 6:6-7; Iuda v. 9; Luca 1:26; Iosua 5:13-15).
      </p>

      <h5>b. Căderea lui Lucifer şi a altor îngeri</h5>
      <p>
        Diavolul a fost odată luceafăr strălucitor, dar mândrin-du-se a căzut. Astăzi este căpetenia îngerilor răi, care nu şi-au păstrat locul şi au căzut (Isaia 14:12-14; Iuda 6; 2 Petru 2:4).
        </p>
      <p>
        Numele lui înseamnă defăimător şi dezbinator. El este numit diavol şi satana, el înşeală întreaga omenire (Apoc. 12:9) şi toţi oamenii necredincioşi zac sub puterea lui (Fapte 26:18; Apoc. 12:7-8; 2 Petru 2:4). El este stăpânul şi dumnezeul veacului acestuia (2 Cor. 4:3-4; Ioan 14:30; Fapte 26:17-18), ucigător de oameni (Genesa 3:4-5), părintele minciunii (Ioan 8:44), ispititorul (Matei 4:3; 1 Tes. 3:5) şi uneltitorul împotriva copiilor lui Dumnezeu (Iov 1, 9-11; Efeseni 2:16).
        </p>
      <p>
        El a fost biruit prin jertfa lui Isus Hristos (Ioan 14:30-31; Efeseni 2:16).
        </p>
      <p>
        Soarta lui va fi aruncarea în iazul cel de foc împreună cu îngerii lui (Mat.25:41; Apoc. 20:10).
      </p>

      <h4>5. Noi credem că Dumnezeu 1-a creat pe om din ţărână</h4>
      <p>
        Omul a fost creat după chipul şi asemănarea lui Dumnezeu: trup, suflet şi duh (Gen. 1:26; 2:27; 1 Tes. 5:23). Omul este o trinitate.
        </p>
      <p>
        Omul a fost creat curat şi nevinovat, el a fost aşezat în grădina Edenului ca s-o lucreze şi s-o păzească (Gen 2:15). El a fost înzestrat cu toate atributele unei fiinţe făcute după chipul şi asemănarea lui Dumnezeu: sentimente, raţiune şi voinţă.
      </p>

      <h4>6. Noi credem că păcatul a intrat în lume datorită neascultării omului de porunca lui Dumnezeu</h4>
      <p>
        „Domnul Dumnezeu a dat omului porunca aceasta: «Poţi să mănânci după plăcere din orice pom din grădină; dar din pomul cunoştinţei binelui şi răului să nu mănânci, căci în ziua în care vei mânca din el, vei muri negreşit»" (Gen. 2:16-17).
        </p>
      <p>
        Lăsându-se ispitit de diavolul, şarpele cel vechi, prin femeie, omul a călcat porunca pe care o primise de la Dumnezeu şi astfel, prin libera sa voinţă, a devenit rob al păcatului, vrăjmaş al lui Dumnezeu, din care cauză a fost dat afară din Eden şi supus suferinţei, trudei şi morţii (Gen. 3). Odată cu căderea sa, Adam i-a tras pe toţi oamenii, care sunt urmaşii săi, în păcat. Toţi au păcătuit (Rom. 5:10-12; Efeseni 2:1-2). Din această pricină nimeni nu se poate mântui singur sau un frate pe fratele său (Ps. 49:7-8). Prin Harul lui Dumnezeu, care este arătat prin Isus Hristos, omul poate fi scăpat din starea de robie a păcatului (Rom. 5:19-21), iar necazurile, truda şi moartea pot conlucra spre binele lui (Rom. 8:28-30; Rom. 5:3-4; Fapte 14:22).
        </p>
      <p>
        Păcatul venit în lume prin neascultarea primilor oameni a afectat întreaga omenire (Rom. 5:19).
        </p>
      <p>
        Păcat este orice încălcare a Cuvântului, voinţei şi poruncii lui Dumnezeu (Rom. 3:23; Gal. 5:19-21). Omul păcătuieşte prin omiterea împlinirii binelui cerut de Dumnezeu sau prin comiterea lucrurilor împotriva voii Sale.
        </p>
      <p>
        Păcat este orice fel de faptă care întinează trupul, sufletul şi duhul: curvia şi preacurvia, necumpătarea, trândăvia, îmbuibarea, împovărarea cu gânduri lumeşti, gânduri rele, sinuciderea, avorturile, vorbirea de rău, vrajba, minciuna, făţărnicia, furtul, înşelătoria, neajutorarea sfinţilor în caz de nevoie, precum şi chinuirea animalelor (Gen. 38:2-10; Prov. 12:10; Rom. 12:13; Gal. 5:19-21; Apoc. 21:8).
        </p>
      <p>
        La fel este păcat: necredinţa, nepăsarea, nesocotirea harului venit prin Isus Hristos, lipsa temerii de Dumnezeu, luarea în deşert a Numelui Său, idolatria, superstiţiile, vrăjitoriile etc.
        </p>
      <p>
        Păcatul, o dată înfăptuit, este urmat de consecinţe (Gal. 6:8). El este boldul morţii şi atrage după sine pedeapsa veşnică a lui Dumnezeu (Apoc. 22:15; 21:8). Plata păcatului este moartea (Rom. 6:23).
      </p>

      <h4>7. Noi credem că harul lui Dumnezeu a făcut şi face posibilă mântuirea omului prin jertfa Domnului Isus Hristos</h4>
      <p>
        Harul este bunătatea şi iubirea nemărginită a lui Dumnezeu, arătat prin Domnul nostru Isus Hristos, faţă de noi păcătoşii (loan 1:17; Efeseni 2:4-7). Nimeni nu se poate mântui prin propriile sale fapte sau binefaceri şi nici prin faptele Legii Vechiului Testament, decât prin Isus Hristos (Gal. 2:27; Efeseni 2:5-9). Harul lucrează împreună cu credinţa. Prin har se capătă trecere înaintea lui
        </p>
      <p>
        Dumnezeu (1 Cor. 15:9-10). Harul este sursa binecuvântărilor (Efeseni 4:7-8; 1 Cor. 15:10; 2 Cor. 1:15; 1 Petru 4:10).
        </p>
      <p>
        Prin har, Dumnezeu ne învaţă cum să trăim în veacul de acum cu cumpătare, dreptate şi evlavie (Tit 2:11-12).
        </p>
      <p>
        Harul este mai presus de mulţimea păcatelor, întrecând mărimea şi mulţimea lor (Rom. 5:20). El poate fi înmulţit în noi în măsura cunoştinţei de Dumnezeu (2 Petru 1:2). El întăreşte inima (Evrei 13:9), ne creşte sufleteşte (2 Petru 3:18) şi drege vorbirea noastră (Col. 4:6).
        </p>
      <p>
        „Fiindcă atât de mult a iubit Dumnezeu lumea că a dat pe singurul Lui Fiu, pentru ca oricine crede în El, să nu piară, ci să aibă viaţă veşnică" (loan 3:16).
      </p>

      <h4>8. Noi credem că mântuirea este condiţionată de credinţă, pocăinţă şi naşterea din nou</h4>

      <h5>a. Credinţa</h5>
      <p>
        „Credinţa este o încredere neclintită în lucrurile nădăjduite şi o puternică încredinţare despre lucrurile care nu se văd" (Evrei 11:1, 2 Cor. 4:18).
        </p>
      <p>
        Credinţa vine prin auzirea Cuvântului lui Dumnezeu (Rom. 10:17). Adevărata credinţă porneşte din inimă (Rom. 10:9). Prin credinţa din inimă se primeşte neprihănirea (Rom. 10:10), mântuirea (Efeseni 2:8), curăţirea inimii (Fapte 15:9), iertarea (Galateni 2:16; Efeseni 2:8; Galateni 3:22), moştenirea şi dreptul de a ne numi copiii lui Dumnezeu (Galateni 3:26).
        </p>
      <p>
        Fără credinţă nimeni nu poate fi plăcut lui Dumnezeu (Evrei 11:6). Credinţa este adevărată şi activă numai atunci când este însoţită de fapte (Iacov 2:14-22). Toţi trebuie să păstrăm credinţa care s-a dat odată pentru totdeauna sfinţilor (Iuda v. 3).
        </p>
      <p>
        În timpul din urmă, mulţi se vor lepăda de credinţa adevărată (1 Tim. 4:1-4). Peste cei ce refuză să creadă adevărul, Dumnezeu lasă o lucrare de rătăcire, drept consecinţă să creadă o minciună spre a fi osândiţi (2 Tes. 2:11-12).
        </p>
      <p>
        Pentru cei care cred, totul este posibil: iertare, vindecări de boli etc. (Mat.8:13; loan 14:12).
      </p>

      <h5>b. Pocăinţa</h5>
      <p>
        Pocăinţa este o poruncă a Domnului Isus (Marcu 1:14-15). Ea reprezintă întoarcerea omului la Dumnezeu de pe căile rătăcite ale păcatului (Isaia 55:7), schimbarea totală a vieţii vechi cu una nouă, după Cuvântul lui Dumnezeu (Rom. 2:4), schimbarea gândirii omului. Ea înseamnă căinţă pentru viaţa trăită în necunoştinţă de Dumnezeu şi încetarea de a mai păcătui (Tit 2:11-13; Plângerile lui Ieremia 3:39-40). „Cine îşi ascunde fărădelegile, nu propăşeşte, dar cine le mărturiseşte şi se lasă de ele capătă îndurare" (Prov. 28:13).
        </p>
      <p>
        Dumnezeu îi cheamă pe toţi oamenii la pocăinţă, voind să nu piară nici unul dintre ei (2 Petru 3:9), ci să ajungă la mântuire (Timotei 2:4). Chiar când păcătuieşte un credincios, el este îndemnat să se pocăiască de faptele săvârşite (Apoc. 2:5; Galateni 6:1). Pocăinţa este adevărată numai când este făcută dintr-o inimă sinceră şi curată, care caută să îndrepte pagubele şi greşelile faţă de aproapele nostru (Luca 19:8; Fapte 20:20-21).
      </p>
      <h5>c. Naşterea din nou.</h5>
      <p>
        Nimeni nu poate deveni un copil al lui Dumnezeu dacă nu este, mai întâi, născut din nou (loan 3:5-8). Oricât de bun ar fi un om, dacă nu este născut din nou, nu poate moşteni Împărăţia lui Dumnezeu (loan 3:3-5). Omul se compune din trup, suflet şi duh.
        </p>
      <p>
        Câtă vreme omul trăieşte în păcat, trupul face ce voieşte, târând sufletul şi duhul, întinându-le prin pofte, patimi, păcate. În felul acesta duhul este mort faţă de Dumnezeu (1 Tes. 5:23, Efeseni 2:1-3).
        </p>
      <p>
        În urma auzirii Cuvântului lui Dumnezeu vine credinţa mântuitoare (Romani 10:17), urmează pocăinţa (Fapte 2:37-38) şi naşterea din nou prin Cuvântul lui Dumnezeu şi Duhul Sfânt (Ioan 3:5, Iacov 1:18, 1 Petru 1:23, Tit 3:5).
        </p>
      <p>
        Cel ce este născut din nou nu mai face ce voieşte ci el umblă şi se lasă cârmuit de Duhul (Galateni 5:25), având în fiinţa sa cârmuirea Duhului Sfânt (Romani 8:1,14).
        </p>
      <p>
        Fără naşterea din nou nu are valoare nici credinţa (Iacov 2:19), nici cunoştinţa (Rom. 1:32) şi nici botezul în apă (Fapte 8:13-24). Cei ce sunt născuţi din Dumnezeu nu păcătuiesc (1 loan 3:9; 5:18), iar cei ce păcătuiesc nu sunt născuţi din nou (1 loan 3:6-10).
      </p>

      <h4>9. Noi credem că avem iertarea lui Dumnezeu ca urmare a primirii Domnului Isus Hristos ca Mântuitor</h4>
      <p>
        Iertarea este un atribut al lui Dumnezeu prin care se dovedeşte bunătatea Lui faţă de oamenii păcătoşi, prin faptul că le şterge tot trecutul lor după ce s-au întors la El (Fapte 10:43; 1 loan 1:9).
        </p>
      <p>
        Odată iertat, păcătosul devine uşurat de întreaga povară a păcatului ce apăsa asupra conştiinţei lui şi astfel el se simte într-adevăr fericit (Ps. 32:1-2). Deci mărturisirea păcatelor este urmată de uşurare sufletească şi iertare din partea lui Dumnezeu (Ps. 32:5; 1 loan 1:9).
        </p>
      <p>
        După ce a primit iertarea, păcătosul iertat trebuie să-L mărturisească pe Isus şi altor păcătoşi (Luca 24:47; Fapte 4:17-20).
      </p>

      <h4>10.	Noi credem că îndreptăţirea credinciosului stă în jertfa Domnului Isus Hristos şi se primeşte prin credinţă, de la Dumnezeu, odată cu mântuirea</h4>
      <p>
        Îndreptăţirea vine direct şi numai din partea lui Dumnezeu prin Isus Hristos. Deci nimeni nu poate să se îndreptăţească înaintea lui Dumnezeu prin faptele lui proprii şi nici prin faptele Legii Vechiului Testament. Singurul mijloc de îndreptăţire este jertfa lui Isus Hristos de pe Golgota (Filipeni 3:9; loan 1:12; Romani 8:1).
        </p>
      <p>
        Îndreptăţirea se capătă prin credinţa în Numele lui Isus Hristos (loan 3:16).
        </p>
      <p>
        Prin îndreptăţire omul capătă starea după voia lui Dumnezeu. După ce omul a ajuns în această stare, are pace cu Dumnezeu (Romani 5:1).
        </p>
      <p>
        Dumnezeu L-a trimis pe Isus Hristos să sufere şi să moară pentru noi, plătind pentru păcatelor noastre prin jertfa Sa pe cruce, purtând astfel pedeapsa pe care o merita păcătosul (2 Cor. 5:21). Îndreptăţirea nu se face prin faptele noastre bune, ci prin Harul divin.
      </p>

      <h4>11.	Noi credem că roada credincioşilor izbăviţi de păcat este sfinţirea (Romani 6:22)</h4>
      <p>
        Sfinţirea înseamnă despărţirea cu totul de păcat şi punerea deoparte pentru Dumnezeu. Orice om întors la Dumnezeu, născut din nou, iertat şi îndreptăţit este considerat sfânt (1 Cor. 6:10-11; Coloseni 3:12; Efeseni 5:6-7).
        </p>
      <p>
        Sfinţirea se realizează în viaţa credinciosului prin sângele Domnului Isus Hristos (Evrei 13:12), prin Cuvântul lui Dumnezeu (loan 15:3, 17:17; 1 Tim. 4:5) şi prin Duhul Sfânt (Rom. 5:5; 14:17; 15:13; Tit 3:4). Sfinţirea trebuie să cuprindă trupul, sufletul şi duhul nostru (Rom. 6:13,19; 1 Tes. 5:23).
        </p>
      <p>
        Procesul sfinţirii exclude întrebuinţarea drogurilor, a fumatului şi a băuturilor alcoolice (Isaia 55:2; Prov 20:1; 23:31-33; Efeseni 5:18), ca şi: homosexualitatea, sinuciderea, eutanasia şi jurămintele deşarte.
      </p>

      <h4>12.	Noi credem în relaţia directă a credinciosului cu Dumnezeu, rugăciunea şi postul fiind două dintre cele mai eficiente modalităţi de apropiere de divinitate</h4>
      <h5>a. Rugăciunea</h5>
      <p>
        Rugăciunea trebuie adresată Părintelui ceresc în Numele Domnului nostru Isus Hristos (loan 16:23-24), prin Duhul Sfânt şi potrivit voinţei Sale (1 loan 5:14; Efeseni 6:18; Iuda 20). Adevărata rugăciune porneşte dintr-o inimă curată, nu după un anumit program învăţat pe dinafară, ci izvorâtă din necesitatea duhovnicească a omului lăuntric (Mat. 6:7; Filipeni 4:6). Adevăraţii închinători trebuie să se roage în Duh şi în adevăr (loan 4:24). Copiii lui Dumnezeu menţin legătura cu El, fără întrerupere, prin rugăciune (1 Tes. 5:17; Efeseni 6:18). Rugăciunea poate fi individuală (Daniel 6:10) şi comună (Fapte 4:24). Rugăciunea în comun (cu toţi odată) este o moştenire din felul de închinare al ucenicilor (Fapte 4:24). În Biserică se pot folosi tot felul de rugăciuni, spre exemplu se poate ruga unul, doi sau trei pe rând, şi ceilalţi să-1 însoţească, spunând „amin" la rugăciunile care se înalţă (1 Cor. 14:16). La fel se poate folosi şi rugăciunea în comun, care dă posibilitatea ca toţi credincioşii să se roage în acelaşi timp şi fiecare după nevoia sa. Rugăciunea în comun făcută în chip ordonat produce o armonie cerească, ca zumzetul miilor de albine în acelaşi stup (1 Cor. 14:33,40). Pentru zidirea sufletească a tuturor este mai potrivit a se ruga două sau trei persoane pe rând (Filip. 2:4).
        </p>
      <p>
        Dumnezeu ascultă rugăciunea celor drepţi, făcută cu credinţă (Iacov 5:15-16; Marcu 11:24; Matei 18:19). Botezul cu Duhul Sfânt şi vindecările de boli sunt răspunsuri la rugăciunile noastre (Luca 11:13; Fapte 8:14-15).
        </p>
      <p>
        Rugăciunile pot fi şi împiedicate prin viaţa netrăită după Cuvântul lui Dumnezeu sau prin păcate ascunse şi nemărturisite (1 Petru 3:7; Iacov 5:16; 1 Ioan 1:9; Iosua 7:10-26; Prov. 28:13).
        </p>
      <p>
        Biblia opreşte închinarea la sfinţi şi la îngeri (Apoc. 19:10; Coloseni 2:18; Fapte 10:26-27). Sfinţii ne pot sluji numai ca model prin felul lor de viaţă (Evrei 13:7; 6:11-12). Mijlocitori în rugăciunile credincioşilor sunt Domnul Isus Hristos şi Duhul Sfânt (Ioan 14:16; 1 Tim. 2:5; Evrei 7:25; Romani 8:25,27)
      </p>

      <h5>b. Postul</h5>
      <p>
        Postul este abţinerea de la întrebuinţarea mâncării şi băuturii de orice fel (Mat. 4:2), pe un timp mărginit, hotărât de fiecare în parte, după nevoile şi puterea sa (Isaia 58:5; Fapte 9:9).
        </p>
      <p>
        Postul are drept scop înfrânarea trupului în vederea obţinerii anumitor biruinţe spirituale (2 Cor. 6:5, 11:27; Fapte 13:2-3, 14:23; Marcu 9:29; 1 Cor. 7:5).
        </p>
      <p>
        Timpul hotărât pentru post este strâns legat de rugăciune. A posti fără a stărui în rugăciune şi fără a rezolva divergenţele cu semenii noştri este numai o formă de evlavie, căreia îi lipseşte puterea (Marcu 9:28-29; Fapte 13:2-3; Isaia 58:6-7).
        </p>
      <p>
        Nimeni nu poate fi obligat să postească.
      </p>

      <h4>13.	Noi credem că botezul în apă, care se acordă persoanelor care cred şi care s-au întors la Dumnezeu, trebuie efectuat printr-o singură cufundare în Numele Tatălui, al Fiului şi al Duhului Sfânt</h4>
      <p>
        Botezul în apă este rânduit de însuşi Domnul nostru Isus Hristos (Mat. 28:19). El este un simbol al morţii faţă de păcat, al învierii la o viaţă nouă în Hristos (Rom. 6:1-4). ÎI pot primi cei care cred şi mărturisesc credinţa (Marcu 16:16, Fapte 8:36).
        </p>
      <p>
        Prin botezul în apă credinciosul este adăugat la numărul membrilor Bisericii lui Dumnezeu (Fapte 2:38-47, Fapte 10:47-48, 1 Petru 3:21).
        </p>
      <p>
        Credinciosul care s-a botezat trebuie să ducă întotdeauna o viaţa curată şi sfântă, trăind după învăţăturile Evangheliei (Matei 28:20, Fapte 2:42, Evrei 3:14). Botezul se primeşte o singură dată prin cufundarea în apă, iar în cazul când acest act nu a fost îndeplinit după Cuvântul Sfintei Scripturi, se poate face a doua oară (Fapte 19:2-6).
        </p>
      <p>
        Botezul în apă se poate primi şi înainte şi după primirea botezului cu Duhul Sfânt (Fapte 8:12-24; 10:44-48). Cei ce au primit botezul în apă fără a fi obţinut botezul cu Duhul Sfânt sunt îndemnaţi după Cuvântul lui Dumnezeu să stăruiască după botezul cu Duhul Sfânt (Fapte 1:4).
        </p>
      <p>
        Botezul în apă este săvârşit numai de pastori şi prezbiteri ordinaţi conform rânduielilor Bisericii nou-testamentale.
        </p>
      <p>
        Copiii credincioşilor, ca şi ceilalţi oameni, nu pot fi botezaţi până ce nu ajung să se convingă singuri că Domnul Isus este Mântuitorul lor personal (Fapte 8:37).
      </p>

      <h4>14.	Noi credem în instituirea Cinei Domnului pentru Biserică</h4>
      <p>
        Participarea la Cina Domnului constituie o proclamare a morţii Domnului
        </p>
      <p>
        Isus Hristos. Cina Domnului este al doilea act de cult după botez ca formă exterioară în Biserica lui Dumnezeu. Ea este instituită de Însuşi Domnul nostru Isus Hristos prin întemeierea legământului nou (Matei 26:28; Luca 22:19-20). Cina Domnului se compune din pâine nedospită şi rodul viţei nefermentat, care simbolizează jertfirea trupului şi vărsarea sângelui Său pentru păcatele noastre (1 Cor. 11:24-25).
        </p>
      <p>
        Cina Domnului este rânduită spre amintirea morţii Domnului Isus şi nu spre iertarea păcatelor, dar cel ce se împărtăşeşte din ea în chip nevrednic se face vinovat de nesocotirea trupului şi sângelui Domnului Isus Hristos şi astfel se împărtăşeşte spre osânda lui însuşi (1 Cor. 11:27-29).
        </p>
      <p>
        Ea este un simbol care exprimă părtăşia noastră cu natura dumnezeiască a Domnului nostru Isus Hristos, adică cu trupul şi sângele Lui (1 Cor. 10:16; 2 Petru 1:4), o amintire a suferinţelor şi morţii Sale (1 Cor. 11:26), o proorocie despre venirea Sa a doua oară. Credincioşii mântuiţi alcătuiesc prin participarea la frângerea pâinii un singur trup (1 Cor. 10:17).
        </p>
      <p>
        Frângerea pâinii are, în săvârşirea şi serbarea ei, două momente: primul simbolizează suferinţele Domnului, al doilea, vizavi de primul, momentul de cercetare de sine din partea credincioşilor (1 Cor. 11:23-29). La Cina Domnului nu pot participa decât membrii activi ai Bisericii, botezaţi în apă sau cu Duhul Sfânt, şi numai după ce s-au cercetat pe sine. Cina se săvârşeşte ori de câte ori este posibil, fără deosebire de zi sau dată. Este recomandabil să se efectueze cel puţin o dată pe lună.
      </p>

      <h4>15.	Noi credem că Domnul Isus a instituit actul spălării picioarelor</h4>
      <p>
        Domnul Isus a spălat picioarele ucenicilor cărora le-a zis: „deci dacă Eu, Domnul şi învăţătorul vostru, v-am spălat picioarele, şi voi sunteţi datori să vă spălaţi picioarele unii altora "(Ioan 13:14).
        </p>
      <p>
        Este amintită şi ca o condiţie de adevărată evlavie în viaţa de credinţă. (1 Tim. 5:10).
      </p>

      <h4>16.	Noi credem în făgăduinţa botezului cu Duhul Sfânt pentru toţi credincioşii</h4>
      <p>
        Duhul Sfânt este promis de Dumnezeu încă prin profetul Ioel (Ioel 2:28-29). Proorocul Ioel vorbeşte de două mari revărsări: ploaia timpurie şi ploaia târzie (Ioel 2:23). Ploaia timpurie s-a revărsat în primele secole, începând cu ziua de Rusalii, la Ierusalim (Fapte 2:2-4), iar ploaia târzie se revarsă în zilele noastre.
        </p>
      <p>
        Duhul Sfânt purcede de la Tatăl prin Fiul (Ioan 15:26). Botezul cu Duhul Sfânt este o pecete pentru ziua răscumpărării (Efeseni 4:30), o arvună a moştenirii (Efeseni 1:13-14), putere de sus pentru slujba mărturisirii Evangheliei depline (Fapte 1:8). El se deosebeşte de naşterea din nou. El se primeşte pe baza credinţei în urma naşterii din nou şi nu poate avea loc în nici un caz înaintea acesteia (Fapte 19:1-6, Efeseni 1:13-14). Ascultând predica lui Filip, samaritenii au primit Cuvântul şi au fost botezaţi în apă; când au venit la ei Petru şi Ioan, aceştia s-au rugat, punându-şi mâinile peste ei, ca să primească Duhul Sfânt (Fapte 8:12-17).
        </p>
      <p>
        Botezul cu Duhul Sfânt se poate primi prin şi fără punerea mâinilor (Fapte 8:17; 19:6; 10:44-46 şi 2:12-17). Botezul cu Duhul Sfânt trebuie cerut (Luca 11:13).
        </p>
      <p>
        Botezul cu Duh Sfânt înseamnă umplerea cu Duhul Sfânt (Fapte 2:4), iar botezul cu foc (Matei 3:11) este viaţa de încercări prin care Dumnezeu trece pe cei sigilaţi (1 Petru 4:12-14 şi 16-19; 1:6-7).
        </p>
      <p>
        Botezul cu Duhul Sfânt este însoţit de semnul vorbirii în alte limbi (Fapte 2:4; 10:46; 19:6).
        </p>
      <p>
        Prin botezul cu Duh Sfânt vine umplerea cu putere pentru o viaţă sfântă în slujba divină. Acest botez poate fi urmat şi de primirea altor daruri (Luca 24:49; Fapte 1:4-8 şi 1 Cor. 12:1-31). Botezul cu Duhul Sfânt este o experienţă unică, iar umplerea cu El poate fi repetată (Fapte 4:3).
        </p>
      <p>
        Potrivit cu Sfânta Scriptură, este necesar ca cei chemaţi de Dumnezeu la vestirea Evangheliei depline mai întâi să stăruie, ca să fie umpluţi cu putere de Sus, pentru ca să-i poată însoţi semnele, minunile şi darurile Duhului Sfânt (Marcu 16:17-18; Luca 22:49, 1 Cor. 2:1-5; Evrei 2:4).
      </p>

      <h4>17.	Noi credem că lucrarea Duhului Sfânt se manifestă în cele nouă daruri</h4>
      <p>
        În urma botezului cu Duhul Sfânt, Duhul Sfânt împarte cum El voieşte diferite daruri duhovniceşti. Sfânta Scriptură ne vorbeşte despre nouă daruri, şi anume: vorbirea despre înţelepciune, vorbirea despre cunoştinţă, credinţa, darul tămăduirilor, puterea de a face minuni, deosebirea duhurilor, proorocia, vorbirea în felurite limbi şi tălmăcirea limbilor (1 Cor. 12:1-10). Toţi credincioşii trebuie să aibă o măsură oarecare de înţelepciune, cunoştinţă şi credinţă.
        </p>
      <p>
        Aceasta prin Cuvântul lui Dumnezeu (Romani: 12:2; Ioan 5:39; Mat. 22:29; Romani 10:17). Darurile Duhului Sfânt sunt supranaturale şi desăvârşite în lu-crarea lor. Exemple: Fapte 8:20; 2 Petru 3:16; Marcu 16:17-18. Credinţa în Domnul Isus Hristos care face parte din roada Duhului (Galateni 5:22) trebuie s-o aibă toţi credincioşii, însă nu toţi primesc darul credinţei (Fapte 3:16). La fel, la primirea botezului cu Duhul Sfânt toţi credincioşii vorbesc în limbi noi, însă nu toţi au darul de a vorbi în felurite limbi (1 Cor. 12:29-30). Darul vorbirii în limbi se foloseşte potrivit cu cele scrise în 1 Cor. 14:27-28. În Biserică trebuie să existe daruri, pentru că ele sunt rânduite şi puse de Dumnezeu pentru zidirea ei sufletească (1 Cor. 12:28); nu primesc toţi credincioşii, ci fiecăruia Duhul Sfânt îi împarte cum voieşte (1 Cor. 12:29-39).
        </p>
      <p>
        Cuvântul lui Dumnezeu ne cere să urmărim dragostea şi să râvnim şi după darurile duhovniceşti (1 Cor. 14:1).
      </p>

      <h4>18.	Noi credem că atât naşterea din nou cât şi botezul cu Duhul Sfânt trebuie să fie urmate de roada Duhului Sfânt</h4>
      <p>
        Roada Duhului Sfânt este recolta unei vieţi sfinte, după ce am devenit copiii lui Dumnezeu, născuţi din nou, cârmuiţi de Duhul Sfânt în toate privinţele (Galateni 5:22-23).
        </p>
      <p>
        Odată cu naşterea din nou, roada Duhului începe să iasă în evidenţă în viaţa credinciosului (1 Ioan 5:18; Tit 3:8). Fiecare credincios născut din nou, botezat cu Duhul Sfânt trebuie să aibă din prisos în viaţa sa roada Duhului Sfânt (Galateni 5:22-23; Matei 7:16-20).
      </p>

      <h4>19.	Noi credem în vindecările divine</h4>
      <p>
        Vindecările divine (supranaturale) intră tot în planul de mântuire înfăptuit pe Golgota prin jertfa de ispăşire a Domnului nostru Isus Hristos (Matei 8:16-17).
        </p>
      <p>
        Vindecarea se poate obţine prin credinţă personală (Luca 8:48), prin darul de vindecare (1 Cor. 12:9; Fapte 3:6) şi prin rugăciunea rostită cu credinţă în urma ungerii cu ulei a bolnavilor, după cele scrise în Iacov 5:14-16.
        </p>
      <p>
        Sfânta Scriptură ne arată că bolile sunt urmarea păcatului şi chiar dacă n-ar fi păcătuit cel bolnav, totuşi sunt o urmare a căderii primului om (Genesa 3:13; Deuteronom 28:15-62; Ioan 5:14).
        </p>
      <p>
        Deoarece bolile şi suferinţele sunt consecinţele căderii în păcat, vindecarea intră, pentru cei ce cred, în acelaşi plan de mântuire ca şi iertarea păcatelor. Domnul Isus a răstignit pe Golgota atât păcatele şi fărădelegile noastre, cât şi bolile şi suferinţele noastre (Isaia 53:4-5; Matei 8:16-17).
        </p>
      <p>
        Ungerea cu untdelemn este orânduită numai pentru credincioşi. Ea se face de către prezbiteri ordinaţi, atunci când bolnavul o cere (Iacov 5:14-16). După ungere se face rugăciune cu punerea mâinilor (Marcu 16:17-18; 6:12-13).
        </p>
      <p>
        Biblia nu opreşte pe nimeni să se lase consultat de medici şi să le urmeze indicaţiile în caz de boală (Marcu 2:17).
      </p>

      <h4>20.	Noi credem în existenţa Bisericii lui Dumnezeu fondată de către Domnul Isus Hristos ca organism unitar şi viu</h4>
      <p>
        Cuvântul „Biserică" este echivalentul a ceea ce în limba greacă se numeşte „Eklesia" şi înseamnă „adunarea celor chemaţi". În înţelesul Sfintelor Scripturi, ea este universală, cuprinde pe toţi credincioşii timpurilor trecute şi prezente, care, auzind glasul lui Isus, s-au hotărât să se întoarcă cu pocăinţă la Domnul, urmând învăţăturile Domnului nostru Isus Hristos şi ale apostolilor Săi (1 Cor. 1:2-2; 2 Cor. 6:14-18).
        </p>
      <p>
        Capul Bisericii este însuşi Domnul nostru Isus Hristos, iar ea formează trupul Lui (Efeseni 1:22-23; Coloseni 1:18). Ea este zidită pe temelia pusă de apostoli şi prooroci, a cărei piatră unghiulară este însuşi Hristos (Efeseni 2:20). Ea este înfăţişată în Noul Testament ca Templul lui Dumnezeu (2 Cor. 6:16), un locaş al lui Dumnezeu prin Duhul Sfânt (Efeseni 2:22) şi trup al lui Hristos.
        </p>
      <p>
        Fiecare credincios, fără deosebire de rasă şi naţionalitate, născut din nou prin Cuvântul lui Dumnezeu şi Duhul Sfânt, care are credinţa deplină dată sfinţilor o dată pentru totdeauna şi de acelaşi preţ cu a apostolilor (Ioan 3:5-6; Tit 3:5-6; Efeseni 2:19-22; Iuda v. 3; 2 Petru 1:1), face parte din Biserică, numită în Cuvântul lui Dumnezeu „Biserica lui Dumnezeu" (1 Tim. 3:15; Fapte 20:28; Mat. 18:20), Biserica celor întâi născuţi, scrişi în cer de Dumnezeu (Evrei 12:23).
        </p>
      <p>
        Menirea ei este de a vesti Cuvântul lui Dumnezeu şi învăţătura Evangheliei (1 Cor. 15:1-6; Marcu 16:15-20).
        </p>
      <p>
        Totodată ea reprezintă mireasa Domnului Isus şi Împărăţia lui Dumnezeu (2 Cor. 11:2; Efeseni 5:25-27).
        </p>
      <p>
        Toate bisericile locale formează o unitate indestructibilă a Bisericii lui Dumnezeu Apostolice Penticostale din România.
      </p>

      <h4>21.	Noi credem că preoţia Vechiului Aşezământ a fost înlocuită în Noul Aşezământ prin slujbele spirituale rânduite în Biserică</h4>
      <p>
        „Şi El a dat pe unii apostoli; pe alţii, prooroci; pe alţii evanghelişti; pe alţii pastori şi în vă ţă tori" (Efeseni 4:11).
        </p>
      <p>
        Apostolii sunt trimişii Domnului, cu misiunea deosebită cum ar fi desţelenirea unui nou câmp pentru Evanghelie (Marcu 16:15-18,20). Aşa au fost cei 12 apostoli, Pavel ca apostol al neamurilor şi alţii (Matei 10:1-4; Marcu 6:30; Romani 1:1; 11:13).
        </p>
      <p>
        Slujba de prooroc se deosebeşte de darul proorociei. Proorocul are cel puţin două daruri: darul vorbirii despre înţelepciune şi darul vorbirii despre cunoştinţă.
        </p>
      <p>
        Evanghelistul este chemat să predice Evanghelia Domnului Isus şi este însoţit de semne şi rezultate deosebite în activitatea lui prin întoarcerea păcătoşilor la Dumnezeu.
        </p>
      <p>
        Pastorul are sarcina păstoririi credincioşilor care compun o biserică locală după indicaţia dată lui Petru de către Domnul Isus (Ioan 21:15-17).
        </p>
      <p>
        Toţi slujitorii reprezintă o singură treaptă spirituală, termenul de episcop, prezbiter şi pastor priveşte aceeaşi slujbă (1 Petru 5:1-2; Fapte 20:17,28; Filipeni 1:1).
        </p>
      <p>
        Din punct de vedere administrativ, se poate deosebi slujba de pastor de cea de prezbiter.
        </p>
      <p>
        Diaconatul a luat fiinţă ca o slujbă cu caracter gospodăresc (Fapte 6:1-6). Mai apoi a căpătat, pentru unii dintre diaconi care s-au remarcat, şi un caracter spiritual (Fapte 6:8-15; 1 Tim. 3:8-12). „Pentru că cei ce slujesc bine ca diaconi, dobândesc un loc de cinste şi o mare îndrăzneală în credinţa care este în Hristos Isus" (1 Tim. 3:13).
      </p>

      <h4>22.	Noi credem că lucrătorii Evangheliei sunt ordinaţi în lucrare prin punerea mâinilor şi rugăciunea făcută de către slujitorii lui Dumnezeu îndreptăţiţi să facă acest lucru (Fapte 6:5-6; 13:2-4; 14:23; Tit 1:5)</h4>
      <p>
        Ordinarea se poate face direct pentru slujba de diacon sau prezbiter, iar după un stagiu oarecare ei pot fi ordinaţi pentru slujba de pastor asistent sau pastor principal. Ordinarea se face în urma unui examen pentru cei care nu sunt absolvenţi de seminar şi în urma unei mărturisiri date în scris în faţa Comisiei de ordinare, de loialitate faţă de Biserica lui Dumnezeu. Diaconii sunt ordinaţi de către doi pastori, iar prezbiterii şi pastorii de către cel puţin trei pastori. Dacă cineva a fost ordinat diacon, trecerea la slujba de prezbiter sau pastor se face printr-o ordinaţiune.
        </p>
      <p>
      </p>
      <p>
        Diaconul se ocupă de administrarea ajutoarelor în Biserică. El poate oficia Cina Domnului, binecuvântări de copii şi înmormântări, dacă pastorul îi cere acest lucru.
        </p>
      <p>
        Prezbiterul poate oficia toate actele de cult la recomandarea pastorului.
        </p>
      <p>
        Pastorul oficiază toate actele de cult şi se ocupă de păstorirea unei Biserici locale.
        </p>
      <p>
        Slujba încredinţată prin actul ordinaţiunii este pe viaţă şi se poate revoca numai dacă cel ordinat a săvârşit abateri grave, dovedite prin mărturia a doi sau a trei martori (1 Tim. 5:19).
        </p>
      <p>
        Actul ordinaţiunii se face la recomandarea unei Biserici locale şi durata slujirii efective depinde tot de hotărârea Bisericii Locale.
      </p>

      <h4>23.	Noi credem că toţi membrii Bisericii lui Dumnezeu au îndatoriri şi drepturi</h4>
      <p>
        Nimeni nu poate să devină membru în Biserica lui Dumnezeu Apostolică Penticostală decât numai după ce şi-a însuşit principiile de credinţă. Biserica îl poate primi de probă, pentru un anumit timp. În acest timp, candidatul este cercetat asupra stării lui sufleteşti şi, după ce a pus capăt păcatelor sale şi a dat dovadă că el cunoaşte toate principiile Bisericii, poate fi botezat în apă şi primit ca membru (2 Petru 1:1; Ioan 17:20-23; 1 Tes. 4:3-8).
        </p>
      <p>
        Membrii se numesc între ei fraţi (Fapte 5:23). Ei sunt datori după Cuvântul lui Dumnezeu să respecte pe cei ce slujesc (Evrei 13:17; 1 Tes. 5:12-13) şi să se supună unii altora în frica lui Hristos (Efeseni 5:21).
        </p>
      <p>
        Membrii sunt datori a stărui în sfinţire, unitate frăţească, în învăţătura Evangheliei şi în dragoste (Fapte 2:42).
        </p>
      <p>
        Cuvântul lui Dumnezeu cere ca tot ce se face în Biserică: rugăciune, propovăduirea Evangheliei, îndemnuri, învăţături, întrebuinţarea darurilor duhovniceşti, cântări de laudă sau în duh, şedinţe ordinare şi extraordinare etc. „toate să se facă în chip cuviincios şi cu rânduială" (1 Cor. 14:40). Fiecare membru este dator să participe în mod regulat la serviciile divine. Fiecare membru este dator să aibă o ţinută curată şi îngrijită. Disciplina în Biserică este un aşezământ biblic impus de Cuvântul lui Dumnezeu. Fiecare membru este dator a se conforma şi proceda potrivit cu cele scrise în Matei 18:15-18.
        </p>
      <p>
        Când cineva cade deodată în vreo greşeală, cei duhovniceşti, mai ales fraţii puşi să supravegheze bunul mers în Biserică, sunt datori a-1 ridica cu duhul blândeţii (Galateni 6:1).
        </p>
      <p>
        Când cineva păcătuieşte şi păcatele lui devin cunoscute de mai mulţi, iar el nu se sfieşte să păcătuiască mai departe, un astfel de om trebuie mustrat în faţa tuturor, ca şi ceilalţi să se teamă (1 Tim. 5:20).
        </p>
      <p>
        Fiecare credincios, conform Cuvântului lui Dumnezeu, primeşte mustrarea şi chiar punerea sub disciplină, mai ales când se abate de la învăţăturile sănătoase ale Evangheliei depline. Disciplina nu are alt scop decât a-1 ridica pe cel căzut sufleteşte şi de a menţine în Biserică autoritatea morală, buna rânduială, dragostea şi legătura frăţească (1 Tes. 5:14; 2 Tes. 3:14-15; Iuda 22).
        </p>
      <p>
        EXCLUDEREA — Biserica are dreptul, chiar datoria, potrivit cu principiile Evangheliei Domnului Isus Hristos şi învăţăturile sfinţilor apostoli, să-i excludă pe acei membri care, după ce au fost îndemnaţi de mai multe ori la pocăinţă pentru abaterile lor, stăruiesc mai departe în săvârşirea păcatului. Prin excludere ei pierd dreptul de membru (Mat. 18:15-18; Rom. 16:17-18; 1 Cor. 5:2; 11:13; 2 Tes. 3:6; Tit 3:10).
        </p>
      <p>
        REPRIMIREA — Cel ce se căieşte din toată inima de abaterile sale şi Biserica are dovezi că viaţa lui s-a îndreptat, că se supune tuturor bunelor rânduieli şi cere să fie reprimit, poate fi reprimit (Luca 17:4; Iacov 5:19).
      </p>

      <h4>24.	Noi credem că familia, prin căsătorie, este o instituţie divină aşezată de Dumnezeu</h4>
      <p>
        Familia este o instituţie divină şi biblică. Ea a fost hotărâtă de Dumnezeu, chiar primilor oameni: Adam şi Eva (Genesa 2:18-24).
        </p>
      <p>
        Prin căsătorie, Dumnezeu face legătura intimă şi sfântă între soţ şi soţie, formând din amândoi un singur trup (Matei 19:5). Cine hotărăşte în inima lui să rămână necăsătorit este liber (1 Cor. 7:7-8, 34, 40).
        </p>
      <p>
        Bărbatul este dator să-şi îngrijească, să-şi iubească şi să-ţi cinstească soţia, ca pe trupul lui (Petru 3:7; Coloseni 3:19). El este capul femeii şi al familiei (1 Cor. 11:13). Soţia a fost creată şi rânduită de Dumnezeu să fie de ajutor bărbatului ei (Genesa 2:18). Ea este datoare să-1 iu-bească şi să i se supună (Tit 2:4-5; Col. 3:18).
        </p>
      <p>
        Căsătoria se oficiază întâi la ofiţerul stării civile, urmând căsătoria religioasă prin binecuvântarea Domnului, în Biserică.
        </p>
      <p>
        Cuvântul lui Dumnezeu nu permite ca un credincios să se căsătorească cu o necredincioasă sau invers (Deut. 7:3; 1 Cor. 7:39; 2 Cor. 6:14)
        </p>
      <p>
        DIVORŢUL — nu este îngăduit de Cuvântul lui Dumnezeu, afară de cazul de adulter dovedit (Matei 19:6-9).
        </p>
      <p>
        Concubinajul şi avortul nu sunt îngăduite de Dumnezeu (Evrei 13:4; 1 Cor. 6:18-20; Galateni 5:19; Exod 20:13).
        </p>
      <p>
        RECĂSĂTORIREA — este îngăduită de Cuvântul lui Dumnezeu numai atunci când unul din cei doi soţi a trecut din viaţă (Rom. 7:1-3).
        </p>
      <p>
        Nu se admite recăsătorirea atunci când despărţirea s-a făcut din alt motiv decât cel biblic. Cuvântul lui Dumnezeu nu admite aceasta, ci doar o reîmpăcare sau renunţare la recăsătorire (1 Cor. 7:10,11).
      </p>

      <h4>25.	Noi credem în rânduiala Bisericii cu privire la binecuvântarea copiilor</h4>
      <p>
        Copiii credincioşilor sunt sfinţi (1 Cor. 7:14). Ei totuşi vor fi aduşi la binecuvântare, unde, după exemplul Domnului Isus (Matei 19:13-15; Marcu 10:13-16), copiii mici sunt luaţi în braţe, iar peste cei mai mari se pun mâinile, rostindu-se textul de binecuvântare de la Numeri 6:24-27, în timp ce li se pronunţă numele. Apoi urmează o scurtă rugăciune pentru copil şi părinţii lui.
      </p>

      <h4>26. Noi credem că dărnicia este o datorie sfântă pentru fiecare credincios</h4>
      <p>
        Dărnicia este aşezată de apostoli în primele Biserici creştine: 1 Cor. 16:1-2; 2 Cor. 8:1-15.
        </p>
      <p>
        În primul rând, prin dărnicie se întăreşte răspândirea Evangheliei. Pentru a ne mântui, Dumnezeu L-a dat pe Fiul Său pentru noi (Ioan 3:16). La fel, pentru a putea ajunge Evanghelia şi la alţi păcătoşi, şi noi suntem datori să facem „partea Domnului" şi să dăm din toate bunurile noastre celor ce ne învaţă şi predică Evanghelia (Galateni 6:6; 1 Tim. 5:17-18; Evrei 7:8).
        </p>
      <p>
        În al doilea rând, sunt ajutaţi cei ce lucrează şi s-au pus cu totul în slujba propovăduirii Cuvântului (1 Tim 5:17-18; Galateni 6:6).
        </p>
      <p>
        În al treilea rând, se urmăreşte (prin dărnicie) ajutorarea săracilor sfinţi, când sunt în nevoi (Matei 25:34-36; Romani 12:13; Proverbe 14:31).
        </p>
      <p>
        Membrii Bisericii lui Dumnezeu sunt obligaţi, potrivit Cuvântului Său, să ajute cu darurile lor benevole orice lucrare la care sunt chemaţi şi mai ales să participe regulat la strângerile care au loc în fiecare duminică în toate Bisericile (1 Cor. 16:1,2).
      </p>

      <h4>27.	Noi credem în datoria credincioşilor de a urmări simplitatea în îmbrăcăminte</h4>
      <p>
        Sfânta Scriptură ne învaţă ca, pe lângă supunere şi cumpătare, să fim cuviincioşi şi în îmbrăcăminte. Credincioşii trebuie să urmărească în îmbrăcămintea lor modestia şi simplitatea: podoaba lor să fie omul ascuns al inimii; un duh blând, liniştit, umplut cu Duhul Sfânt, care este de mare preţ înaintea lui Dumnezeu (1 Tim. 2:9-10; 1 Petru 3:1-6). Credincioşii botezaţi cu Duhul Sfânt formează un popor sfânt şi deosebit de lume, deci ei nu trebuie să se potrivească principiilor lumii, nici să iubească deprinderile ei, care întotdeauna înclină spre păcat. Prietenia lumii este vrăjmăşie cu Dumnezeu (Romani 12:2; Iacov 4:4; Matei 9:24; 1 Corinteni 3:15).
      </p>

      <h4>28.	Noi credem că duminica este ziua Domnului</h4>
      <p>
        Noi serbăm ca zi de rugăciune şi odihnă duminica. În ea ne odihnim şi participăm regulat la serviciile divine ale Bisericii, pentru zidirea noastră sufletească.
        </p>
      <p>
        Serbăm această zi în amintirea învierii Domnului Isus Hristos, după modelul creştinilor din Biserica primară (1 Cor. 16:2; Fapte 20:7; 2:1; Ioan 20:19-20). În afară de duminică, se oficiază servicii divine în orice altă sărbătoare, precum şi în zilele stabilite de Biserică (Efeseni 6:18; 1 Tim. 2:1-4).
      </p>

      <h4>29.	Noi credem că voia lui Dumnezeu este să fim loiali faţă de stat şi faţă de autorităţile statului</h4>
      <p>
        Cuvântul lui Dumnezeu ne impune recunoaşterea şi respectarea autorităţilor şi a ordinii de stat (1 Petru 2:13-17; Tit 3:1,2), pe de altă parte aceasta este şi o datorie naţională şi cetăţenească. Autorităţile statului sunt rânduite de Dumnezeu, pentru păstrarea ordinii publice, pentru conducerea ţării şi binele poporului (Romani 13:1-5). Noi suntem datori a ne supune legilor ţării, potrivit Cuvântului lui Dumnezeu. Suntem datori să plătim dările către stat şi să ne îndeplinim îndatoririle ce ne revin (Romani 13:6-7).
        </p>
      <p>
        Noi suntem datori a ne ruga pentru autorităţi şi înalta cârmuire (1 Tim. 2:1-3).
      </p>

      <h4>30.	Noi credem că suntem datori să-i iubim pe toţi oamenii, indiferent de rasă, naţionalitate sau convingere religioasă (Marcu 9:38, 39; Filipeni 3:16)</h4>
      <p>
        Critica şi hula împotriva altui cult religios creează antagonism şi ceartă. Nici un credincios adevărat din Biserica lui Dumnezeu nu calomniază alte culte religioase (Marcu 9:38-39; Filipeni 3:16).
      </p>

      <h4>31.	Noi credem în a doua venire a Domnului Isus Hristos şi în evenimentele escatologice care vor urma</h4>
      <h5>a. Răpirea Bisericii</h5>
      <p>
        Credem în răpirea Bisericii înainte de arătarea Domnului Isus Hristos pe norii cerului şi
        </p>
      <p>
        înainte de necazul cel mare (Isaia 26:20-21; Luca 21:40-44; Luca 17:34-36; 1 Cor. 15:40-44; 1 Tes. 4:13-18). „Despre ziua aceea şi despre ceasul acela, nu ştie nimeni: nici îngerii din ceruri, nici Fiul, ci numai Tatăl" (Matei 24:36).
        </p>
      <p>
        După răpire va avea loc, în cer, judecata pentru răsplătirea credincioşilor (Romani 14:10; 2 Cor. 5:10) şi apoi nunta Mielului (Apoc. 19:7-9).
      </p>

      <h5>b. Necazul cel mare şi arătarea lui Anticrist</h5>
      <p>
        După răpirea Bisericii şi ridicarea Duhului Sfânt, care azi opresc arătarea Anticristului (2 Tes. 2:7-8), va urma o mare strâmtorare din pricina arătării lui, cum n-a fost vreodată pe pământ şi nici nu va mai fi (Matei 24:21; Apoc. 6).
        </p>
      <p>
        Duhul Anticristului lucrează deja azi în lume prin ucenicii săi (1 Ioan 2:18) şi ţinta lui este să-L tăgăduiască pe Tatăl, pe Fiul şi pe Duhul Sfânt, spre a nimici cu desăvârşire credinţa în Dumnezeu şi astfel să se poată prezenta pe sine drept Dumnezeu prin semnele şi minunile pe care le va face (2 Tes. 2:4; 9:12; Apoc. 13:13; 16:4). Domnul Isus a venit ca Dumnezeu întrupat în om şi, după ce Şi-a dat viaţa ca jertfă de ispăşire pentru iertarea păcatelor, a trimis ucenicii Săi să propovăduiască Evanghelia, iar Anticrist este mesia cel fals, adică Satan întrupat în om şi care vine în numele sau (Ioan 5:43). El are deja mulţi ucenici în lume, care propovăduiesc fie necredinţa, fie învăţături false, spre a-i deschide drumul în lume (1 Ioan 2:18; Luca 18:8).
        </p>
      <p>
        După ce fiii lui Israel vor recunoaşte amăgirea, în care au fost atraşi de Anticrist, se vor întoarce la Mesia Cel adevărat, care este Isus Hristos, Domnul nostru (Rom. 9:27-29, 11:26; Ieremia 30:7).
      </p>

      <h5>c. Venirea Domnului Isus Hristos în slavă</h5>
      <p>
        “Îndată după acele zile de necaz, soarele se va întuneca, luna nu-şi va mai da lumina ei, stelele vor cădea din cer şi puterile cerului vor fi clătinate. Atunci se va arăta în cer semnul Fiului omului, toate seminţiile pământului se vor boci şi vor vedea pe Fiul omului venind pe norii cerului cu putere şi cu mare slavă" (Matei 24:29-30; Marcu 13:24-26).
        </p>
      <p>
        Domnul va fi însoţit de zecile de mii de sfinţi ai Săi, care vor sta la dreapta puterii Sale (1 Tes. 4:16-17; Iuda v. 14-15).
        </p>
      <p>
        Atunci Anticristul va fi nimicit cu suflarea gurii Sale: fiara şi proorocul mincinos vor fi aruncaţi de-a dreptul în iazul de foc (Apoc. 19:20; 2 Tes. 2:3; Ieremia 23:33; Maleahi 4:3; Apoc. 19:21). Diavolul va fi aruncat în fântâna adâncului, unde va fi ţinut legat timp de o mie de ani (Apoc. 20:1-30).
      </p>

      <h5>d. Împărăţia lui Dumnezeu pe pământ sau Mileniul</h5>
      <p>
        Împărăţia lui Dumnezeu din zilele noastre este spirituală (Luca 17:20). Ea nu se întemeiază pe obiceiuri sau forme exterioare, ci constă în sfinţirea vieţii, pacea şi bucuria care vin de la Duhul Sfânt (Rom. 14:17).
        </p>
      <p>
        În împărăţia lui Dumnezeu intră toţi aceia care sunt născuţi din nou (Ioan 3:3-6).
        </p>
      <p>
        Noi aşteptăm însă ca Împărăţia lui Dumnezeu să vină în chip vizibil pe pământ şi ea va dura o mie de ani, potrivit Cuvântului Său (Mat. 6:10; Apoc. 20:4). Domnul îşi va întemeia Împărăţia Sa pe temei de dreptate şi sfinţenie, stăpânind întreg pământul, fiind El Domn al Domnilor şi Rege al Regilor (Apoc. 11:15; 1 Cor. 15:24; Apoc. 5:10, 19:16).
        </p>
      <p>
        În timpul Împărăţiei de o mie de ani va fi pace deplină pe pământ, fiindcă diavolul va fi legat şi nu va avea cine să mai înşele neamurile şi va exista armonie chiar între fiare; cruzimea nu va mai avea loc (Isaia 11:1-10). Zilele oamenilor vor fi multe, ca zilele copacilor (Isaia 65:9-25). Atunci Împărăţia lui Dumnezeu va fi pe tot pământul (Apoc. 11:15).
      </p>

      <h5>e. Învierea universală şi judecata din urmă</h5>
      <p>
        Moartea este trecerea omului din viaţa aceasta în viaţa veşnică, cu alte cuvinte despărţirea sufletului şi a duhului de trup. Trupul se întoarce în ţărână, de unde a fost luat (Genesa 3:19), iar sufletul este viu, conştient şi trăieşte în lumea de dincolo împreună cu duhul, de care este nedespărţit.
        </p>
      <p>
        Toţi oamenii, fără deosebire, trebuie să moară, fiindcă toţi au păcătuit (Rom. 5:12). Pentru credincioşi moartea este o adormire în care aşteaptă învierea (1 Tes. 4:13-16), o despărţire de trupul firesc, pentru a fi îmbrăcaţi cu trupul ceresc (2 Cor. 5:1-8), o părăsire a lumii, ca să fie la un loc cu Domnul, într-un loc de pace, fericire şi odihnă (Luca 23:43; Filipeni 1:23; Apoc. 14:13).
        </p>
      <p>
        Credincioşii se vor înfăţişa, la timpul hotărât, la scaunul de judecată al lui Hristos, pentru ca fiecare să-şi primească răsplata cerească din mâna Domnului pentru toate faptele şi ostenelile pe care le-a depus pe pământ în lucrul Său (2 Cor. 5:10; Rom. 14:10-12).
        </p>
      <p>
        Moartea necredincioşilor este un act însoţit de groază, spaimă şi remuşcări sfâşietoare. Sufletul lor se duce într-un loc de chinuri, aşteptând înfricoşata zi a judecăţii din urmă (Iuda 7). După Împărăţia de o mie de ani, Satana va fi din nou dezlegat pentru puţină vreme, timp în care va înşela neamurile (Gog şi Magog), ca să facă război împotriva sfinţilor. „...dar din cer s-a pogorât un foc care i-a mistuit" (Apoc. 20:7-10).
        </p>
      <p>
        După aceasta vor învia toţi, fără deosebire, cei care nu au avut parte de învierea cea dintâi (Apoc. 20:5). Marea va da afară morţii care erau în ea, la fel moartea şi locuinţa morţilor vor da şi ele înapoi pe morţii lor (Apoc. 20:13,14) şi toţi vor trece la judecata de la Tronul Alb (Apoc. 20:11,12). Şi fiecare va fi judecat după faptele lui şi după gradul cunoştinţei pe care a avut-o (Apoc. 20:12; Rom. 2:12). Cei ce nu au fost găsiţi scrişi în cartea vieţii vor fi aruncaţi în iazul de foc (Mat. 25:32-46; Apoc. 20:15).
      </p>

      <h5>f. Cerul nou şi pământul cel nou. Viaţa veşnică</h5>
      <p>
        Viaţa veşnică este de obârşie divină şi este un dar al lui Dumnezeu (Rom. 6:23; Evrei 7:16). Ea este promisă credincioşilor (1 Ioan 2:25; 2 Tim. 1:1); ea se capătă de la Dumnezeu prin credinţa în Isus Hristos (1 Ioan 5:11; Ioan 3:15), căci El însuşi este viaţa veşnică (1 Ioan 5:20).
        </p>
      <p>
        Pentru a obţine viaţa veşnică trebuie să ne luptăm, stăruind în fapte bune (Romani 2:7), fiindcă noi suntem chemaţi la ea (1 Tim. 6:12). Ea este un drept de moştenire pentru veacul viitor (Marcu 10:30), este numită şi viaţa viitoare (1 Tim. 4:8) şi vor intra în ea numai cei neprihăniţi, care au luptat cu credincioşie pe pământ, răbdând ispitele. Ei vor primi, la timpul hotărât, cununa vieţii veşnice (1 Tim. 6:12).
        </p>
      <p>
        „Apoi am văzut un cer nou şi un pământ nou; pentru că cerul dintâi şi pământul dintâi pieriseră şi marea nu mai era. Şi eu am văzut coborându-se din cer de la Dumnezeu cetatea sfântă, noul Ierusalim, gătită ca o mireasă împodobită pentru bărbatul ei. Şi am auzit un glas tare, care ieşea din scaunul de domnie şi zicea: «Iată cortul lui Dumnezeu cu oamenii! El va locui cu ei, şi ei vor fi poporul Lui, şi Dumnezeu însuşi va fi cu ei. El va fi Dumnezeul lor. El va şterge orice lacrimă din ochii lor. Şi moartea nu va mai fi. Nu va mai fi nici tânguire, nici ţipăt, nici durere, pentru că lucrurile	dintâi	au	trecut»” (Apoc.21:1-4).
      </p>
    </div>
  )
}

export default BeliefSection;
