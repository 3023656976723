import React, { useEffect } from 'react'
import AppRouter from './router/AppRouter'
import configureStore from './redux-store/store/configureStore'
import { firebase } from './firebase/firebase'
// import LoadingPage from './components/LoadingPage'
import { Provider } from 'react-redux'
import { login, logout } from './redux-store/actions/auth.js'
import { startSetVideos } from './redux-store/actions/videos'
import { startSetLive } from './redux-store/actions/live'
// import axios from './axios/axios'

const App = () => {
    // const [isLoading, toggleIsLoading] = useState(true)
    const store = configureStore()

    useEffect(() => {   // componentDidMount
    //     const user = getCurrentUser()

    //     if (user) {
    //         const token = user.token

    //         axios.post(`/jwt`, { token })
    //             .then((response) => {
    //                 store.dispatch(login(response.data._id));
    //                 return response.data
    //             })
    //             .catch((error) => {
    //                 localStorage.removeItem('user')
    //                 store.dispatch(logout());
    //                 return { error }
    //             })
    //     } else {
    //         store.dispatch(logout());
    //     }

        store.dispatch(startSetVideos())
        store.dispatch(startSetLive())
    }, [store])

    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            store.dispatch(login(user.uid));
        } else {
            store.dispatch(logout());
        }

        // toggleIsLoading(false)
    });

    // if (isLoading) {
    //     return <LoadingPage />
    // }

    return (
        <div>
            <Provider store={store}>
                <AppRouter />
            </Provider>
        </div>
    )
}

export default App