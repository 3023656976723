import React from 'react';
import Map from './Map';
import { city, country, county, fiscalAccount, ibanAccounts, localAddress, romanianTexts, zipCode } from './utils/constants';


const ContactPage = () => {
  const emailUs = (<a href="mailto:office@betelsighet.ro"><i className="far fa-envelope"></i> Email Us</a>)
  const locationIcon = (<img className="icon" src="/logos/localization.png" alt="" />)
  const location = (<p>{romanianTexts.fullChurchName}<br />{localAddress}, {city}<br />{zipCode} {county}, {country}<br />Cod fiscal: {fiscalAccount.fiscalNo}</p>)
  const FacebookPageWidget = (<div className="fb-page" data-href="https://www.facebook.com/betelsighet/" data-tabs="timeline" data-width="500" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/betelsighet/" className="fb-xfbml-parse-ignore"></blockquote></div>)
  // const YoutubeSubscribeWidget = (<div class="g-ytsubscribe" data-channelid="UCMN5bRpid3A09cIrkz97H2A" data-layout="full" data-count="default"></div>)

  // componentDidMount
  // useEffect(() => {
  //   document.addEventListener('fb_init', (e) => window.FB.XFBML.parse());
  // }, [])

  return (
    <div className="header-space">
      <Map />
      {/* <PageFragment title={locationIcon} text={location} /> */}
      <div className="row">
        <div className="col-md homepage-text dark-grey-background">
          {locationIcon}
          {/* <hr /> */}
          {location}
        </div>
      </div>
      <div className="row">
        <div className="col-md homepage-text beige-background">
          <h3>Conturi</h3>
          <hr />
          <span>{ibanAccounts}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md homepage-text dark-grey-background">
          <h3>{emailUs}</h3>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md homepage-text beige-background">
          <h3>{YoutubeSubscribeWidget}</h3>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md homepage-text dark-grey-background">
          <h3>{FacebookPageWidget}</h3>
        </div>
      </div>
    </div>
  )
}

export default ContactPage;
