import React from "react";
import Modal from "react-responsive-modal";
import LiveForm from "./LiveForm";
import { startEditLive } from "../../redux-store/actions/live";
import { connect } from "react-redux";

const EditLiveModal = ({ open, toggleModal, live, startEditLive }) => {
  const onSubmit = (updates) => {
    startEditLive(updates)
    toggleModal()
  };

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      styles={{
        modal: {
          maxWidth: "unset",
          width: "70%",
          padding: "unset",
          border: 0,
          background: "#EDE5E1"
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)"
        },
        closeButton: {
          background: "white"
        }
      }}
      center
    >
      <div>
        <div className="page-header">
          <div className="content-container">
            <h1 className="page-header__title">Edit Live</h1>
          </div>
        </div>
        <div className="content-container">
          <LiveForm
            live={live}
            onSubmitCallback={onSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  live: state.live
});

const mapDispatchToProps = (dispatch) => ({
  startEditLive: (updates) => dispatch(startEditLive(updates))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLiveModal);
