import React, { useState } from 'react'
import { connect } from 'react-redux'
import { DateRangePicker } from 'react-dates'
import { setTextFilter, setStartDate, setEndDate } from '../../redux-store/actions/filters'

const VideoListFilters = ({ filters, setTextFilter, setStartDate, setEndDate }) => {
    const [calendarFocused, setCalendarFocused] = useState(null)

    const onDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const onFocusChange = (calendarFocused) => {
        setCalendarFocused(calendarFocused)
    }

    const onTextChange = (e) => {
        setTextFilter(e.target.value)
    }

    return (
        <>
            <div className="input-group">
                <div className="input-group__item">
                    <input
                        type="text"
                        className="text-input"
                        placeholder="Caută program"
                        value={filters.text}
                        onChange={onTextChange}
                    />
                </div>
                <div className="input-group__item">
                    <DateRangePicker
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        onDatesChange={onDatesChange}
                        focusedInput={calendarFocused}
                        onFocusChange={onFocusChange}
                        showClearDates={true}
                        numberOfMonths={1}
                        isOutsideRange={() => false}
                        keepOpenOnDateSelect={true}
                        startDateId={JSON.stringify(filters.startDate)}
                        endDateId={JSON.stringify(filters.endDate)}
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    filters: state.filters
});

const mapDispatchToProps = (dispatch) => ({
    setTextFilter: (text) => dispatch(setTextFilter(text)),
    setStartDate: (date) => dispatch(setStartDate(date)),
    setEndDate: (date) => dispatch(setEndDate(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoListFilters);