import React from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';

export default class VideoForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: props.video ? props.video.title : '',
            description: props.video ? props.video.description : '',
            url: props.video ? props.video.url : '',
            createdAt: props.video ? moment(props.video.createdAt) : moment(),
            calendarFocused: false,
            error: ''
        };
    }
    onTitleChange = (e) => {
        e.persist();    // if we want to use 'e' inside of a callback we have to call this function first
        this.setState(() => ({ title: e.target.value }));
    };
    onDescriptionChange = (e) => {
        const description = e.target.value;
        this.setState(() => ({ description }));
    };
    onUrlChange = (e) => {
        const url = e.target.value;
        this.setState(() => ({ url }));
    };
    onDateChange = (createdAt) => {
        if (createdAt)  // This prevents the user from being able to clear the value
            this.setState(() => ({ createdAt }));
        // else    // If the date value is cleared, go back to the current date
        //     this.setState(() => ({ createdAt: moment() }));
    };
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }));
    };
    onSubmit = (e) => {
        e.preventDefault();

        if (!this.state.title || !this.state.url) {
            // Set error state equal to 'Please provide title and URL.'
            this.setState(() => ({ error: 'Please provide title and URL.' }));
        } else {
            // Clear the error
            this.setState(() => ({ error: '' }));

            this.props.onSubmitCallback({
                title: this.state.title,
                url: this.state.url,
                description: this.state.description,
                createdAt: this.state.createdAt.valueOf()
            });
        }
    };
    render() {
        return (
            <form className="form" onSubmit={this.onSubmit}>
                {this.state.error && <p className="form__error">{this.state.error}</p>}
                <input
                    placeholder="Add a title for your video"
                    className="text-input"
                    value={this.state.title}
                    onChange={this.onTitleChange}
                    required
                />
                <input
                    type="text"
                    placeholder="URL"
                    className="text-input"
                    value={this.state.url}
                    onChange={this.onUrlChange}
                    required
                />
                <textarea
                    type="text"
                    placeholder="Description"
                    autoFocus
                    className="textarea"
                    value={this.state.description}
                    onChange={this.onDescriptionChange}
                />
                <SingleDatePicker
                    date={this.state.createdAt}             // sets where the date value is get from
                    onDateChange={this.onDateChange}        // sets the handler when setting a new value to the calendar
                    focused={this.state.calendarFocused}    // checks if the calendar should be displayed
                    onFocusChange={this.onFocusChange}      // changes the focus value if it changes
                    numberOfMonths={1}                      // sets the number of months to be displayed at the same time
                    isOutsideRange={() => false}         // disables the selected day verification
                />
                <div>
                    <button className="button">Save Video</button>
                </div>
            </form>
        );
    }
}
