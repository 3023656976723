import React from 'react';
import BoardDisplay from '../BoardDisplay';

const Board = () => {
  return (
    <div>
      <h2>COMITETUL BISERICII</h2>
      <BoardDisplay />
      {/* <br /> */}
      {/* <h2>SLUJITORII BISERICII</h2> */}
    </div>
  )
}

export default Board;
