import React from "react";

export const englishTexts = {
  fullChurchName: "Betel Christian Pentecostal Church",
  churchName: "Betel Pentecostal Church",
  shortChurchName: "Betel Sighet",
  logoChurchName: "Betel Sighet Church",
  about: "About",
  aboutUs: "About Us",
  history: "History",
  whatWeBelieve: "What We Believe",
  schedule: "Special Events",
};

export const romanianTexts = {
  fullChurchName: 'Biserica Creștină Penticostală "Betel"',
  churchName: "Biserica Penticostală Betel",
  shortChurchName: "Betel Sighet",
  logoChurchName: "Biserica Betel Sighet",
  about: "Despre",
  aboutUs: "Despre Noi",
  history: "Istoric",
  whatWeBelieve: "Ce credem noi",
  schedule: "Evenimente speciale",
};

export const localAddress = "Popa Lupu 9";
export const fullAddress = "Popa Lupu 9, Sighetu Marmației 435500";
export const street = "Popa Lupu";
export const buidingNo = "9";
export const city = "Sighetu Marmației";
export const county = "Maramureș";
export const country = "România";
export const zipCode = "435500";
export const currentYear = new Date().getFullYear();
export const churchLocation = { lat: 47.9287563, lng: 23.8996176 };
export const url = "https://www.betelsighet.ro";
export const unknownContactPhotoPath = "/logos/unknown.png";

export const fiscalAccount = {
  name: 'Biserica Penticostală "Betel"',
  fiscalNo: "10299865",
  address: "str.Popa Lupu, nr.9",
  city: "Sighetu Marmației",
  county: "Maramureș",
};

export const ibanAccountsDetails = [
  {
    name: "Biserica Penticostală Betel",
    coin: "RON (Lei)",
    iban: "RO74BRDE250SV55890672500",
    swift: "BRDEROBU",
  },
  {
    name: "Biserica Penticostală Betel",
    coin: "EUR (Euro)",
    iban: "RO87BRDE250SV06197312500",
    swift: "BRDEROBU",
  },
  {
    name: "Biserica Penticostală Betel",
    coin: "USD (Dolari)",
    iban: "RO68BRDE250SV02105262500",
    swift: "BRDEROBU",
  },
];

export const ibanAccounts = (
  <div>
    <p>{fiscalAccount.name}</p>
    <p>
      {`COD FISCAL: ${fiscalAccount.fiscalNo},`}{" "}
      {ibanAccountsDetails[0].swift
        ? `SWIFT: ${ibanAccountsDetails[0].swift}`
        : ""}
    </p>
    {ibanAccountsDetails.map((account) => (
      <p key={JSON.stringify(account)}>
        IBAN {account.coin}: {account.iban}
        <br />
      </p>
    ))}
  </div>
);

export const contacts = {
  SimionBumbar: {
    fullName: "Simion Bumbar",
    email: "simion.bumbar@betelsighet.ro",
    phoneNo: "+40 745 611 377",
    photo: "/images/board/SimionBumbar.jpg",
    position: "Pastor Coordonator",
  },
  GrigoreTodoran: {
    fullName: "Grigore Todoran",
    email: "grigore.todoran@betelsighet.ro",
    phoneNo: "+40 744 494 992",
    photo: "/images/board/GrigoreTodoran.jpg",
    position: "Pastor Asistent",
  },
  MariusTurza: {
    fullName: "Marius Turza",
    email: "marius.turza@betelsighet.ro",
    phoneNo: "+40 745 760 900",
    photo: "/images/board/MariusTurza.jpg",
    position: "Pastor - Secretar",
  },
};

export const pastorsBoard = [
  {
    fullName: contacts.GrigoreTodoran.fullName,
    photo: contacts.GrigoreTodoran.photo,
    position: "Pastor",
  },
  {
    fullName: contacts.SimionBumbar.fullName,
    photo: contacts.SimionBumbar.photo,
    position: "Pastor",
  },
  {
    fullName: contacts.MariusTurza.fullName,
    photo: contacts.MariusTurza.photo,
    position: "Pastor",
  },
];

export const board = [
  contacts.SimionBumbar,
  contacts.GrigoreTodoran,
  contacts.MariusTurza,
  {
    fullName: "Mihai Dialog",
    // email: "",
    // phoneNo: "",
    photo: "/images/board/MihaiDialog.jpg",
    position: "Prezbiter",
  },
  {
    fullName: "Florin Pop",
    // email: "",
    // phoneNo: "",
    photo: "/images/board/FlorinPop.jpg",
    position: "Prezbiter",
  },
  {
    fullName: "Vasile Pipaș",
    // email: "",
    // phoneNo: "",
    photo: "/images/board/VasilePipas.jpg",
    position: "Prezbiter",
  },
  {
    fullName: "Alexandru Palas",
    // email: "",
    // phoneNo: "",
    // photo: "/images/board/GhitaPisuc.jpg",
    position: "Diacon",
  },
  {
    fullName: "Samuel Suciu",
    // email: "",
    // phoneNo: "",
    photo: "/images/board/SamuelSuciu.jpg",
    position: "Casier",
  },
  {
    fullName: "Samuel Bumbar",
    // email: "",
    // phoneNo: "",
    photo: "/images/board/SamuelBumbar.jpg",
    position: "Membru comitet",
    // private: true
  },
];

export const videos = [
  {
    description: "Program Duminică Seara 26.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/3245583385461530/",
  },
  {
    description: "Program Duminică Dimineața 26.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/553424288919272/",
  },
  {
    description: "Program Joi 23.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/265130534524762/",
  },
  {
    description: "Program a doua zi de Înviere Luni 20.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/521446468733332/",
  },
  {
    description: "Program de Înviere Duminică Seara 19.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/1531377390372048",
  },
  {
    description: "Program de Înviere Duminică Dimineața 19.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/272327227131073/",
  },
  {
    description: "Program Vinerea Mare 17.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/3004208179645237/",
  },
  {
    description: "Program Joia Mare 16.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/1503721759799116/",
  },
  {
    description: "Program Duminică Seara 12.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/1038136486587155/",
  },
  {
    description: "Program Duminică Dimineața 12.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/249580483110486/",
  },
  {
    description: "Program Joi 09.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/154967865848741/",
  },
  {
    description: "Program Duminică Seara 05.04.2020 Partea 2",
    url: "https://www.facebook.com/betelsighet/videos/944832395973508/",
  },
  {
    description: "Program Duminică Seara 05.04.2020 Partea 1",
    url: "https://www.facebook.com/betelsighet/videos/663359284430150/",
  },
  {
    description: "Program Duminică Dimineața 05.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/218216786102537/",
  },
  {
    description: "Program Joi 02.04.2020",
    url: "https://www.facebook.com/betelsighet/videos/891902667924448/",
  },
  {
    description: "Duminică 29.03.2020",
    url: "https://www.facebook.com/betelsighet/videos/1990446401099186/",
  },
];
