// Videos Reducer

const videosReducerDefaultState = [];

export default (state = videosReducerDefaultState, action) => {
    switch (action.type) {
        case 'ADD_VIDEO':
            // state.push(action.video);             // Modifies the 'state' variable
            // return state.concat(action.video);    // Doesn't modify the 'state' variable
            return [
                ...state,
                action.video
            ];  // Doesn't modify the 'state' variable

        case 'REMOVE_VIDEO':
            // return state.filter(({video}) => (video.id !== action.id));
            return state.filter(({ id }) => id !== action.id);

        case 'EDIT_VIDEO':
            return state.map((video) => {
                if (video.id === action.id) {
                    return {
                        ...video,
                        ...action.updates
                    };
                } else {
                    return video;
                }
            });

        case 'SET_VIDEOS':
            return action.videos;

        default:
            return state;
    }
};
