import React from "react";
import { Helmet } from "react-helmet";
import { romanianTexts } from "../utils/constants";
import { Link } from "react-router-dom";

const PageBanner = ({
  bannerMobilePosition = "center",
  darkness = 50,
  imageUrl = "/images/about_us.svg",
  linkUrl = "",
  title,
  titleColor,
  videoUrl = "",
  videoAlt = "Your browser does not support the video tag.",
}) => {
  let videoHTML;
  if (videoUrl) {
    imageUrl = "";
    videoHTML = videoUrl ? (
      <video className='background-video' autoPlay loop muted>
        <source src={videoUrl} type='video/mp4' />
        <source src={videoUrl} type='video/ogg' />
      </video>
    ) : (
      videoAlt
    );
  }

  const style = imageUrl
    ? {
        background: `linear-gradient( rgba(0, 0, 0, ${
          darkness / 100
        }), rgba(0, 0, 0, ${darkness / 100}) ), url(${imageUrl})`,
        color: titleColor,
      }
    : {};

  const titleHTML = title ? (
    <div className='row'>
      <div className='col align-self-center'>
        <h1>{title}</h1>
      </div>
    </div>
  ) : (
    ""
  );

  let className = "page-banner-mobile-center";

  if (bannerMobilePosition === "right") {
    className = "page-banner-mobile-right";
  }

  let HTML = (
    <div className={"page-banner " + className} style={style}>
      <Helmet>
        <meta property='og:image' content={`${romanianTexts.url}${imageUrl}`} />
        <meta
          itemprop='thumbnailUrl'
          content={`${romanianTexts.url}${imageUrl}`}
        />
        <link rel='image_src' href={`${romanianTexts.url}${imageUrl}`} />
        <meta itemprop='image' content={`${romanianTexts.url}${imageUrl}`} />
        <meta
          name='twitter:image'
          content={`${romanianTexts.url}${imageUrl}`}
        />
        <meta property='og:title' content={title} />
        <meta name='twitter:title' content={title} />
      </Helmet>
      {videoHTML}
      {titleHTML}
    </div>
  );

  if (linkUrl) return <Link to={linkUrl}>{HTML}</Link>;
  else return HTML;
};

export default PageBanner;
