import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import authReducer from '../reducers/auth'
import videosReducer from '../reducers/videos'
import liveReducer from '../reducers/live'
import filtersReducer from '../reducers/filters'

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) ) || compose;//window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => (
    // Store creation
    createStore(
        combineReducers({
            videos: videosReducer,
            live: liveReducer,
            filters: filtersReducer,
            auth: authReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()     // Reduced this line by creating the composeEnhancers variable
    )
);
