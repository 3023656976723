import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { startLoginAPI } from '../redux-store/actions/auth';
import { startLoginWithFirebase } from "../redux-store/actions/auth";
import { isEmail } from "validator";
import { useForm } from "react-hook-form";
import Modal from "react-responsive-modal";

export const LoginPage = ({ history, startLogin }) => {
  const { errors, handleSubmit, register } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, changeIsModalOpen] = useState(false);

  const toggleModal = () => {
    changeIsModalOpen(!isModalOpen);
  };

  const onSubmit = async (e) => {
    const response = await startLogin(email, password);

    if (response.error) {
      // console.error(response.error)
      toggleModal();

      // Closing the modal after 2 seconds
      setTimeout(() => {
        changeIsModalOpen(false);
      }, 2000);

      return;
    }

    history.push("/");
  };

  return (
    <div className='auth-lock-container'>
      <div className='auth-lock-center'>
        <div className='auth-lock-widget'>
          <div className='auth-container'>
            <div className='internal-wrapper'>
              <div className='auth-header'>
                <Link to='/'>
                  <img
                    className='my-auth-logo'
                    src='/logos/logo_menu.svg'
                    srcSet='/logos/logo_menu@2x.png 2x'
                    alt='BETEL SIGHET'
                  />
                </Link>
                <p>ONLY FOR ADMINISTRATION</p>
              </div>
              <form
                className='content-wrapper'
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className='body-wrapper'>
                  <div className='form-body'>
                    <div className='form-cell'>
                      <input
                        className='form-input'
                        type='email'
                        inputMode='email'
                        name='email'
                        placeholder='email'
                        autoComplete='off'
                        autoCapitalize='off'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        ref={register({
                          required: true,
                          validate: (email) => isEmail(email),
                        })}
                        required
                      />
                    </div>
                    {errors.email && (
                      <span className='error'>Invalid email</span>
                    )}

                    <div className='form-cell'>
                      <input
                        className='form-input'
                        type='password'
                        name='password'
                        autoComplete='off'
                        autoCapitalize='off'
                        placeholder='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        ref={register({
                          required: true,
                          pattern: /^[ -~]{7,63}$/,
                        })}
                        required
                      />
                    </div>
                    {errors.password && (
                      <span className='error'>Invalid password</span>
                    )}
                  </div>
                </div>
                <button className='submit-button'>Log in</button>
              </form>
            </div>
          </div>
        </div>
        <br />
        {/* <div className="auth-lock-widget">
          <button id="customBtn" class="customGPlusSignIn" onClick={startLogin}>
            <span class="icon"></span>
            <span class="buttonText">Sign In with Google</span>
          </button>
        </div> */}
      </div>
      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        showCloseIcon={false}
        styles={{
          modal: {
            maxWidth: "unset",
            width: "50%",
            padding: "10px",
            border: 0,
            background: "#EDE5E1",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
        center
      >
        <span className='error'>Invalid email or password</span>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // startLogin: (email, password) => dispatch(startLoginAPI(email, password))
  startLogin: (email, password) =>
    dispatch(startLoginWithFirebase(email, password)),
});

export default connect(undefined, mapDispatchToProps)(LoginPage);

// import React, { useContext } from 'react'
// import Context from '../context/context'
// import LoadingPage from './LoadingPage'
// import { startLogin } from '../actions/auth'
// import { firebase, firebaseui } from '../firebase/firebase'
// import { hasPermission } from '../actions/auth.js';

// export const LoginPage = () => {
//   // const { dispatch } = useContext(Context)

//   // const login = () => dispatch(startLogin())

//   const uiConfig = {
//     callbacks: {
//       signInSuccessWithAuthResult: function (authResult, redirectUrl) {
//         // User successfully signed in.
//         // Return type determines whether we continue the redirect automatically
//         // or whether we leave that to developer to handle.

//         if (!hasPermission()) {
//           firebase.auth().signOut()
//           return false;
//         }

//         return true;
//       }
//       //   uiShown: function() {
//       //     // The widget is rendered.
//       //     // Hide the loader.
//       //     document.getElementById('loader').style.display = 'none';
//       //   }
//     },
//     // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
//     credentialHelper: firebaseui.auth.CredentialHelper.NONE,
//     signInFlow: 'popup',
//     // URL to redirect to on success
//     // signInSuccessUrl: '/',
//     signInOptions: [
//       // List of OAuth providers supported.
//       // Leave the lines as is for the providers you want to offer your users.
//       firebase.auth.EmailAuthProvider.PROVIDER_ID,
//       // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       // firebase.auth.FacebookAuthProvider.PROVIDER_ID
//     ]
//     // // Terms of service url.
//     // tosUrl: '<your-tos-url>',
//     // // Privacy policy url.
//     // privacyPolicyUrl: '<your-privacy-policy-url>'
//     // // Other config options...
//   }

//   // Initialize the FirebaseUI Widget using Firebase.
//   const ui = new firebaseui.auth.AuthUI(firebase.auth());

//   ui.start('#firebaseui-auth-container', uiConfig)

//   return (
//     <>
//       {/* <div className="login-wrapper"> */}
//       {/* <button onClick={login}>Login</button> */}
//       <h1>Welcome to My Awesome App</h1>
//       <div id="firebaseui-auth-container"></div>
//       {/* <div id="loader">Loading...</div> */}
//       {/* </div> */}
//     </>
//   )
// }

// // export default LoginPage;
// export default connect(undefined, mapDispatchToProps)(LoginPage);
