import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => (
  <Route {...rest} component={(props) => (
    isAuthenticated ? (
      <Redirect to="/" />
    ) : (
        <>
          <Component {...props} />
        </>
      )
  )} />
);

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid
});

export default connect(mapStateToProps)(PublicRoute);

// import React from 'react';
// import { connect } from 'react-redux';
// import { Route, Redirect } from 'react-router-dom';

// export const PublicRoute = ({isAuthenticated, component: Component, ...rest }) => (
//   <Route {...rest} component={(props) => (
//     isAuthenticated ? (
//       <Redirect to="/" />
//     ) : (
//       <Header />
//       <Component {...props} />
//       <Footer />
//     )
//   )} />
// );

// const mapStateToProps = (state) => ({
//   isAuthenticated: !!state.auth.uid
// });

// export default connect(mapStateToProps)(PublicRoute);