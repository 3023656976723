import React from 'react';
import { unknownContactPhotoPath } from './utils/constants';

const ContactDisplay = ({ contact = {}, backgroundColor = "beige" }) => {
  let backgroundColorClass = "";

  switch (backgroundColor) {
    case "beige":
      backgroundColorClass = "beige-background";
      break;

    case "brown":
      backgroundColorClass = "brown-background";
      break;

    case "cream":
      backgroundColorClass = "cream-background";
      break;

    case "dark-grey":
      backgroundColorClass = "dark-grey-background";
      break;

    case "light-blue":
      backgroundColorClass = "light-blue-background";
      break;

    case "khaki":
      backgroundColorClass = "khaki-background";
      break;

    default:
      backgroundColorClass = "beige-background";
      break;
  }

  if (contact.private)
    contact = { position: contact.position };

  let photoHTML = (contact.photo) ? (<img src={contact.photo} alt="Contact" />) : (<img src={unknownContactPhotoPath} alt="Unknown" />)
  let nameHTML = (contact.fullName) ? (<h3>{contact.fullName}</h3>) : (<h3>Nespecificat</h3>)
  let positionHTML = (contact.position) ? (<h5>{`${contact.position}`}</h5>) : ""
  let emailHTML = (contact.email) ? (<p>Email: <a href={`mailto:${contact.email}`}>{contact.email}</a></p>) : ""
  let phoneNoHTML = (contact.phoneNo) ? (<p>Telefon: <a href={`tel:${contact.phoneNo}`}>{contact.phoneNo}</a></p>) : ""
  let hrHTML = (emailHTML && phoneNoHTML) ? (<hr />) : ""

  return (
    <div className={`col-md homepage-text ${backgroundColorClass}`}>
      {photoHTML}
      {nameHTML}
      {positionHTML}
      {hrHTML}
      {emailHTML}
      {phoneNoHTML}
    </div>
  )
}

export default ContactDisplay;
