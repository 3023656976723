import React from 'react';

const SocialWidget = () => (
  <>
  <div className="social-subscribe-wrapper-fixed-bottom-right">
    <div className="g-ytsubscribe" data-channelid="UCMN5bRpid3A09cIrkz97H2A" data-layout="default" data-count="hidden"></div>
    <div className="fb-like" data-href="https://www.facebook.com/betelsighet" data-width="" data-layout="button_count" data-action="like" data-size="large" data-share="true"></div>
  </div>
  {/* <div className="social-subscribe-wrapper-fixed-right">
    <div className="g-ytsubscribe" data-channelid="UCMN5bRpid3A09cIrkz97H2A" data-layout="default" data-count="hidden"></div>
    <div className="fb-like" data-href="https://www.facebook.com/betelsighet" data-width="100" data-layout="box_count" data-action="like" data-size="large" data-share="true"></div>
  </div> */}
  </>
)

export default SocialWidget;