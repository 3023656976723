import React from 'react';
import { connect } from 'react-redux';
import Modal from "react-responsive-modal";
import { startEditVideo, startRemoveVideo } from '../../redux-store/actions/videos';
import VideoForm from './VideoForm';

const EditVideoModal = ({ open, toggleModal, startEditVideo, startRemoveVideo, video, videoId }) => {
  const onSubmit = (video) => {
    startEditVideo(videoId, video)
    toggleModal()
  };

  const onRemove = () => {
    startRemoveVideo({ id: video.id });
    toggleModal()
  };

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      styles={{
        modal: {
          maxWidth: "unset",
          width: "70%",
          padding: "unset",
          border: 0,
          background: "#EDE5E1"
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)"
        }
      }}
      center
    >
      <div>
        <div className="page-header">
          <div className="content-container">
            <h1 className="page-header__title">Edit Video</h1>
          </div>
        </div>
        <div className="content-container">
          <VideoForm
            video={video}
            onSubmitCallback={onSubmit}
          />
          <button className="button button--secondary" onClick={onRemove}>Remove Video</button>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, props) => ({
  // video: state.videos.find((video) => video.id === props.match.params.id)
  video: state.videos.find((video) => video.id === props.videoId)
});

const mapDispatchToProps = (dispatch) => ({
  startEditVideo: (id, video) => dispatch(startEditVideo(id, video)),
  startRemoveVideo: (data) => dispatch(startRemoveVideo(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditVideoModal);
