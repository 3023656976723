import React from 'react';
import HomePageSlider from './HomePageSlider';
import HomePageOurBelief from './HomePageOurBelief';
import HomePageMeetingTimes from './HomePageMeetingTimes';
import HomePageOurBoard from './HomePageOurBoard';
import Map from '../Map';
import PageFragment from '../page/PageFragment';

const HomePage = () => (
  <div className="home-page">
    <HomePageSlider />
    <HomePageOurBelief />
    <HomePageMeetingTimes />
    <hr className="separator" />
    <HomePageOurBoard />
    <hr className="separator" />
    <PageFragment
      title="INFORMAȚII"
      text="Pentru mai multe detalii despre biserică, accesați butonul de mai jos."
      buttonText="Mai multe detalii"
      buttonUrl="/about"
    />
    <Map isMarkerShown={true} />
  </div>
)

export default HomePage;
