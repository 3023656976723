import axios from '../../axios/axios'
import { firebase } from '../../firebase/firebase';

export const login = (uid) => ({
  type: 'LOGIN',
  uid
});

export const startLoginAPI = (email, password) => {
  return (dispatch) => {
    return axios.post(`/users/login`, { email, password })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data))
          dispatch(login(response.data.user._id))

          return response.data
        }

        return { error: response }
      })
      .catch((error) => {
        return { error }
      })
  }
};

export const startLoginWithFirebase = (email, password) => {
  return () => (
    firebase.auth().signInWithEmailAndPassword(email, password).catch(function (error) {
      // Handle Errors here.
      return { error }
    })
  )
}

export const logout = () => ({
  type: 'LOGOUT'
});

export const startLogoutAPI = () => {
  return (dispatch) => {
    localStorage.removeItem("user")
    dispatch(logout())
  }
}

export const startLogoutWithFirebase = () => {
  return () => {
    return firebase.auth().signOut();
  }
}

export const getCurrentUser = () => JSON.parse(localStorage.getItem("user"))

// export const hasPermission = async (user) => {
//   let hasPermission = false;
//   firebase.database().ref(`UIDs/${user}`)
//     .once('value')
//     .then((snapshot => {
//       console.log(`User: ${snapshot.val()}`)
//       hasPermission = snapshot.val()
//     }))
//     .catch(() => hasPermission = false)

//   if (await hasPermission) {
//     // if (hasPermission) {
//     console.log(`User return: ${hasPermission}`)
//     return true;
//   } else {
//     console.log(`User return: ${hasPermission}`)
//     return false;
//   }

//   // return await hasPermission
// };
