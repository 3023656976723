import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
// import { startLoginAPI, startLogoutAPI } from '../redux-store/actions/auth.js';
import {
  startLoginWithFirebase,
  startLogoutWithFirebase,
} from "../redux-store/actions/auth.js";

export const Header = ({ isAuthenticated, startLogout }) => {
  const [collapsed, setCollapsed] = useState(true);

  const navBarToggleClick = () => setCollapsed(!collapsed);

  const navLinkClick = () => setCollapsed(true);

  let collapsedClass = "collapse";

  if (!collapsed) {
    collapsedClass = "";
  }

  // let loginNavItem = (isAuthenticated) ? "" : (<NavLink to="/login" className="nav-link nav-link-highlighted-beige">Login</NavLink>)
  let logoutNavItem = isAuthenticated ? (
    <button
      className='nav-link nav-link-highlighted-beige'
      onClick={startLogout}
    >
      Logout
    </button>
  ) : (
    ""
  );

  return (
    <nav className='my-navbar navbar navbar-trans fixed-top navbar-expand-lg'>
      <Link to='/' className='navbar-brand' onClick={navLinkClick}>
        <img
          className='my-navbar-logo'
          src='/logos/logo_menu.svg'
          srcSet='/logos/logo_menu@2x.png 2x'
          alt='BETEL SIGHET'
        />
      </Link>
      <button
        className='navbar-toggler navbar-dark'
        type='button'
        onClick={navBarToggleClick}
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div
        className={collapsedClass + " navbar-collapse my-navbar-collapse"}
        id='navbarSupportedContent'
      >
        <ul className='navbar-nav mr-auto'></ul>
        <ul className='navbar-nav my-navbar-content'>
          <li className='nav-item'>
            <NavLink
              to='/about'
              className='nav-link'
              activeClassName='active'
              onClick={navLinkClick}
            >
              Despre noi
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/ministries'
              className='nav-link'
              activeClassName='active'
              onClick={navLinkClick}
            >
              Program
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/contact'
              className='nav-link'
              activeClassName='active'
              onClick={navLinkClick}
            >
              Contact
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/videos'
              className='nav-link'
              activeClassName='active'
              onClick={navLinkClick}
            >
              Arhiva video
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/live'
              className='nav-link nav-link-highlighted-red'
              activeClassName='active'
              onClick={navLinkClick}
            >
              LIVE
            </NavLink>
          </li>
          {/* <li className="nav-item">
            {loginNavItem}
          </li> */}
          <li className='nav-item'>{logoutNavItem}</li>
        </ul>
      </div>
    </nav>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // startLogin: (email, password) => dispatch(startLoginAPI(email, password)),
  // startLogout: () => dispatch(startLogoutAPI())
  startLogin: (email, password) =>
    dispatch(startLoginWithFirebase(email, password)),
  startLogout: () => dispatch(startLogoutWithFirebase()),
});

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
