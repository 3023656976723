import React from 'react';
import PageFragment from '../page/PageFragment';
import { pastorsBoard } from '../utils/constants';
import ContactDisplay from '../ContactDisplay';

const HomePageOurBoard = () => {

  return (
    <div>
      <PageFragment
        title="PASTORII BISERICII"
        buttonText="Întreaga conducere"
        buttonUrl="/about/board"
      />
      <div className="row">
        {
          pastorsBoard.map(contact => (
            <ContactDisplay key={JSON.stringify(contact)} contact={contact} backgroundColor="cream" />
          ))
        }
      </div>
    </div>
  );
}

export default HomePageOurBoard;
