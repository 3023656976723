import database from '../../firebase/firebase';

// // EDIT_VIDEO
// export const editLiveUrl = (url) => ({
//   type: 'EDIT_LIVE_URL',
//   url
// })

// export const startEditLiveUrl = (url = "") => (
//   (dispatch) => (
//     database.ref(`videos/live/url`).set(url).then(() => (
//       dispatch(editLiveUrl(url))
//     ))
//   )
// )

// EDIT_VIDEO
export const editLive = (updates) => ({
  type: 'EDIT_LIVE',
  updates
});

export const startEditLive = (updates) => {
  return (dispatch) => {
      return database.ref(`videos/live`).update(updates).then(() => {
          dispatch(editLive(updates));
      });
  };
};

export const setLive = (live) => ({
  type: 'SET_LIVE',
  live
})

export const startSetLive = () => (
  (dispatch) => (
    database.ref(`videos/live`).once('value').then((snapshot) => (
      dispatch(setLive(snapshot.val()))
    ))
  )
)

export const toggleShowLive = (showLive) => ({
  type: "TOGGLE_SHOW_LIVE",
  showLive
})

export const startToggleShowLive = (showLive) => (
  (dispatch) => (
    database.ref(`videos/live/showLive`).set(showLive).then(() => (
      dispatch(toggleShowLive(showLive))
    ))
  )
)