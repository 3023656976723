import database from '../../firebase/firebase';

// ADD_VIDEO
export const addVideo = (video) => ({
    type: 'ADD_VIDEO',
    video
});

export const startAddVideo = (videoData = {}) => {
    return (dispatch) => {
        const { title = '', url = '', description = '', createdAt = 0 } = videoData; // alternate way for the overload initialization (like on addVideo)
        const video = { title, url, description, createdAt };

        return database.ref(`videos/ministries`).push(video).then((ref) => {
            dispatch(addVideo({
                id: ref.key,
                ...video
            }));
        });
    };
};

// REMOVE_VIDEO
export const removeVideo = ({ id } = {}) => ({
    type: 'REMOVE_VIDEO',
    id
});

export const startRemoveVideo = ({ id } = {}) => {
    return (dispatch) => {
        return database.ref(`videos/ministries/${id}`).remove().then(() => {
            dispatch(removeVideo({ id }));
        });
    };
};

// EDIT_VIDEO
export const editVideo = (id, updates) => ({
    type: 'EDIT_VIDEO',
    id,
    updates
});

export const startEditVideo = (id, updates) => {
    return (dispatch) => {
        return database.ref(`videos/ministries/${id}`).update(updates).then(() => {
            dispatch(editVideo(id, updates));
        });
    };
};

// SET_VIDEOS
export const setVideos = (videos) => ({
    type: 'SET_VIDEOS',
    videos
});

export const startSetVideos = () => {
    return (dispatch) => {
        return database.ref(`videos/ministries`).once('value').then((snapshot) => {
            const videos = [];

            snapshot.forEach((childSnapshot) => {
                videos.push({
                    id: childSnapshot.key,
                    ...childSnapshot.val()
                });
            });

            dispatch(setVideos(videos));
        });
    };
};