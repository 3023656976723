import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { connect } from 'react-redux';

const LiveForm = ({ live, onSubmitCallback, userId }) => {
  const [liveUrl, setLiveUrl] = useState(live ? live.url : '')
  const [nextMinistry, setNextMinistry] = useState(live ? moment(live.nextMinistry) : moment())
  const [calendarFocused, setCalendarFocused] = useState(false)
  const [error, setError] = useState('')

  const onDateChange = (time) => {
    if (time)  // This prevents the user from being able to clear the value
      setNextMinistry(time);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!liveUrl) {
      setError('Please provide an URL.')
    } else {
      // Clear the error
      setError('')
      onSubmitCallback({
        url: liveUrl,
        nextMinistry: nextMinistry.valueOf(),
        lastUpdatedBy: userId
      })
    }
  };

  return (
    <form className="form" onSubmit={onSubmit}>
      {error && <p className="form__error">{error}</p>}
      <input
        type="text"
        placeholder="URL"
        className="text-input"
        value={liveUrl}
        onChange={(e) => setLiveUrl(e.target.value)}
        required
      />
      <SingleDatePicker
        date={nextMinistry}             // sets where the date value is get from
        onDateChange={onDateChange}        // sets the handler when setting a new value to the calendar
        focused={calendarFocused}    // checks if the calendar should be displayed
        onFocusChange={() => setCalendarFocused(!calendarFocused)}      // changes the focus value if it changes
        numberOfMonths={1}                      // sets the number of months to be displayed at the same time
        isOutsideRange={() => false}         // disables the selected day verification
      />
      <div>
        <button className="button">Save Live</button>
      </div>
    </form>
  );
};

// export default LiveForm

const mapStateToProps = (state) => ({
  userId: state.auth.uid,
});

// Connect Video List
export default connect(mapStateToProps)(LiveForm);