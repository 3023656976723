import React from 'react';
import ContactDisplay from './ContactDisplay';
import { board } from './utils/constants';

const BoardDisplay = () => {
  return (
    <div className="row">
      {
        board.map(contact => (
          <ContactDisplay key={JSON.stringify(contact)} contact={contact} backgroundColor="cream" />
        ))
      }
    </div>
  );
}

export default BoardDisplay;