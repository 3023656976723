import React from "react";
import BeliefSection from "./BeliefSection";
import BoardSection from "./BoardSection";
import Page from "../page/Page";
import PageBanner from "../page/PageBanner";
import PageSidebar from "../page/PageSidebar";
import PageSection from "../page/PageSection";
import WelcomeSection from "./WelcomeSection";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSectionChange = (
    title,
    bannerImage,
    bannerDarkness,
    bannerMobilePosition
  ) => {
    this.setState({
      title,
      currentBannerImage: bannerImage,
      bannerDarkness,
      bannerMobilePosition,
    });
  };

  render() {
    return (
      <Page>
        <PageBanner
          title={this.state.title}
          imageUrl={this.state.currentBannerImage}
          darkness={this.state.bannerDarkness}
          bannerMobilePosition={this.state.bannerMobilePosition}
          titleColor='#EDE5E1'
        />
        <PageSidebar
          onSectionChange={this.handleSectionChange}
          currentSection={this.state.currentSection}
        >
          <PageSection
            linkTitle='Despre'
            bannerTitle='Despre biserică'
            bannerImageUrl='/images/about_us.svg'
            url='/about'
            bannerMobilePosition='right'
          >
            <WelcomeSection />
          </PageSection>

          {/* <PageSection
            linkTitle="Istoric"
            bannerTitle="CUM A ÎNCEPUT TOTUL"
            bannerImageUrl="/images/botez_b2000.jpg"
            url="/about/history"
            bannerMobilePosition="right">
            <HistorySection />
            <HistorySectionMore />
          </PageSection> */}

          <PageSection
            linkTitle='Mărturisirea de credință'
            bannerTitle='MĂRTURISIREA NOASTRĂ DE CREDINȚĂ'
            bannerImageUrl='/images/wallpapers/bible_banner.png'
            url='/about/belief'
            bannerMobilePosition='right'
            bannerDarkness='40'
          >
            <BeliefSection />
          </PageSection>

          <PageSection
            linkTitle='Conducerea bisericii'
            bannerImageUrl='/images/church_cropped.svg'
            url='/about/board'
            bannerMobilePosition='center'
          >
            <BoardSection />
          </PageSection>

          {/* <PageSection
            linkTitle="FAQ"
            bannerImageUrl="/images/about_us.svg"
            url="/about/faq"
            bannerMobilePosition="right">
            <FAQSection />
          </PageSection> */}
        </PageSidebar>
      </Page>
    );
  }
}

export default AboutPage;
