import React from 'react';
import VideoList from './VideoList';
import VideoListFilters from './VideoListFilters';
import VideosSummary from './VideosSummary';
import Page from '../page/Page';
import { connect } from 'react-redux';
import VideosAdminHeader from './VideosAdminHeader';

const VideoDashbordPage = ({ isAuthenticated }) => {
    if (isAuthenticated) {
        return (
            <>
                <div className="header-space" />
                <Page>
                    <h1>Arhiva video</h1>
                    <hr />
                    <VideosAdminHeader />
                    <VideoListFilters />
                    <VideosSummary />
                    <VideoList />
                </Page>
            </>
        )
    } else {
        return (
            <>
                <div className="header-space" />
                <Page>
                    <h1>Arhiva video</h1>
                    <hr />
                    <VideoListFilters />
                    <VideosSummary />
                    <VideoList />
                </Page>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid
})

export default connect(mapStateToProps)(VideoDashbordPage);

// import React from 'react';
// import VideoList from './VideoList';
// import VideoListFilters from './VideoListFilters';
// import VideosSummary from './VideosSummary';
// import Page from '../page/Page';

// const VideoDashbordPage = () => (
//     <>
//         <div className="header-space" />
//         <Page>
//             <VideosSummary />
//             <VideoListFilters />
//             <VideoList />
//         </Page>
//     </>
// );

// export default VideoDashbordPage;