import moment from "moment";
import memoizeOne from 'memoize-one';

// Get visible videos
export default (videos, { text, startDate, endDate }) => {
    const filter = memoizeOne((videos, { text, startDate, endDate }) => videos.filter((video) => {
        const startDateMatch = startDate ? startDate.isSameOrBefore(moment(video.createdAt), 'day') : true;
        const endDateMatch = endDate ? endDate.isSameOrAfter(moment(video.createdAt), 'day') : true;
        const textMatch = text ? video.title.toLowerCase().includes(text.toLowerCase()) : true;
        const descriptionMatch = text ? video.description.toLowerCase().includes(text.toLowerCase()) : true;

        return startDateMatch && endDateMatch && (textMatch || descriptionMatch);
    }).sort((a, b) => { // it must return 1 or -1
        return a.createdAt < b.createdAt ? 1 : -1;
    }))

    return filter(videos, { text, startDate, endDate })
};