import React from 'react'
import ReactPlayer from "react-player"
import { connect } from 'react-redux'
import { startSetLive } from '../../redux-store/actions/live'
// import LoadingPage from '../LoadingPage'
import Page from '../page/Page'
import LiveAdminHeader from './LiveAdminHeader'
// import Countdown from 'react-countdown'

const LiveDashboardPage = ({ isAuthenticated, live, startSetLive }) => {
  // const [isLoading, toggleIsLoading] = useState(true)
  let liveURL = live.showLive ? live.url : ""

  // useEffect(() => {   // componentDidMount
  //   startSetLive().then(() => {
  //     toggleIsLoading(false)
  //   })
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // if (!live.showLive) {
  //   setTimeout(() => (
  //     window.location.reload(false)
  //   ), 30000); // runs every 30 seconds.
  // }

  // if (isLoading) {
  //   return <LoadingPage />
  // }

  if (isAuthenticated) {
    return (
      <>
        <div className="header-space" />
        <Page>
          <h1>Live</h1>
          <hr />
          <LiveAdminHeader />
          <hr />
          {/* <Countdown date={Date.now() + 10000} /> */}
          <div className="page-video">
            <ReactPlayer
              className="live-video"
              url={liveURL}
              controls={true}
              playing={true}
            />
          </div>
        </Page>
      </>
    )
  } else {
    return (
      <>
        <div className="header-space" />
        <Page>
          <h1>Live</h1>
          <hr />
          {/* <Countdown date={Date.now() + 10000} /> */}
          <div className="page-video">
            <ReactPlayer
              className="live-video"
              url={liveURL}
              controls={true}
              playing={true}
            />
          </div>
        </Page>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid,
  live: state.live
});

const mapDispatchToProps = (dispatch) => ({
  startSetLive: () => dispatch(startSetLive())
})

// Connect Video List
export default connect(mapStateToProps, mapDispatchToProps)(LiveDashboardPage);