// Live Reducer

const liveReducerDefaultState = [];

export default (state = liveReducerDefaultState, action) => {
  switch (action.type) {
    // case 'EDIT_LIVE_URL':
    //   return {
    //     ...state,
    //     url: action.url
    //   }
    case 'EDIT_LIVE':
      return {
        ...state,
        ...action.updates
      }

    case 'TOGGLE_SHOW_LIVE':
      return {
        ...state,
        showLive: action.showLive
      }

    case 'SET_LIVE':
      return action.live

    default:
      return state
  }
};