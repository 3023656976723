import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
// import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import UniRoute from './UniRoute'
import NotFoundPage from '../components/NotFoundPage'
import AboutPage from '../components/about/AboutPage'
import ContactPage from '../components/ContactPage'
import { createBrowserHistory } from 'history'
import HomePage from '../components/home/HomePage'
import LiveDashboardPage from '../components/videos/LiveDashboardPage'
import MinistriesPage from '../components/MinistriesPage'
import piwik from './piwik'
import ScrollToTop from 'react-router-scroll-top'
import SocialWidget from '../components/SocialWidget'
import LoginPage from '../components/LoginPage'
import VideoDashbordPage from '../components/videos/VideoDashbordPage'

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router basename="/" history={piwik.connectToHistory(history)}>
    <>
      {/* <Header /> */}
      <SocialWidget />
      <ScrollToTop>
        <Switch>
          <UniRoute exact path="/" component={HomePage} />
          <UniRoute path="/index.html" component={() => (<Redirect to="/" />)} /> {/* Redirect the index.html link to the main link (when redirecting http to https) */}
          <UniRoute path="/about" component={AboutPage} />
          <UniRoute path="/contact" component={ContactPage} />
          <UniRoute path="/ministries" component={MinistriesPage} />
          <UniRoute path="/live" component={LiveDashboardPage} />
          <PublicRoute path="/login" component={LoginPage} />
          <UniRoute path="/videos" component={VideoDashbordPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </ScrollToTop>
      {/* <Footer /> */}
    </>
  </Router>
);

export default AppRouter;
