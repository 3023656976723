import React from 'react';
import BibleVerse from '../utils/BibleVerse';

const WelcomeSection = () => {
  return (
    <div>
      <h2>BUN VENIT</h2>
      <p>
        Biserica Penticostală Betel Sighetu Marmației poartă numele Betel începând cu data de 7 decembrie 2003, când a avut loc consacrarea locașului de închinare.
      </p>
      <p>
        Cuvântul „Betel” înseamnă Casa lui Dumnezeu și provine din limba ebraică: „beth” care tradus înseamnă „casă”, iar „El-” este unul din numele lui Dumnezeu.
      </p>
      <p>
        Betel a fost locul unde patriarhul Iacov a avut o întâlnire cu Dumnezeu, care i-a vorbit într-un mod deosebit, i-a făcut promisiuni deosebite și i-a dat garanții deosebite, iar Iacov a luat decizii deosebite în ceea ce privește relația lui cu Dumnezeu. Iacov și-a dat seama că locul acela era un loc special și a zis: <BibleVerse reference="GEN.28.17" text="„I-a fost frică și a zis: „Cât de înfricoșat este locul acesta! Aici este casa lui Dumnezeu, aici este poarta cerurilor!”” (Geneza 28:17)" />.
      </p>
      <p>
        Biserica Betel Sighet și-a propus ca locașul de închinare pe care l-a pus la dispoziția lui Dumnezeu, să fie locul unde să vină oamenii ca să se întâlnească cu Dumnezeu, să Îl cunoască pe Domnul Isus ca Mântuitor și să înceapă o relație personală cu El.
      </p>
      <p>
        Ca slujitori ai Domnului Isus Hristos și ai bisericii căutăm să oferim hrană spirituală membrilor bisericii, prin propovăduirea Cuvântului lui Dumnezeu ca Biserica Domnului - trupul lui Hristos - să crească în toate privințele, să fie un trup bine închegat, în care mădularele trupului să se îngrijească deopotrivă unele de altele.
      </p>
      <p>
        Conștienți fiind de chemarea pe care ne-a făcut-o Dumnezeu de-a fi slujitorii Lui, dorim să ne ducem la îndeplinire mandatul încredințat de El, în conformitate cu cuvintele apostolului Pavel care a spus: <BibleVerse reference="2CO.5.18-20" text="„Și toate lucrurile acestea sunt de la Dumnezeu, care ne-a împăcat cu El prin Isus Hristos și ne-a încredinţat slujba împăcării; că adică, Dumnezeu era în Hristos, împăcând lumea cu Sine, neţinându-le în socoteală păcatele lor, și ne-a încredinţat nouă propovăduirea acestei împăcări. Noi dar, suntem trimiși împuterniciţi ai lui Hristos; și, ca și cum Dumnezeu ar îndemna prin noi, vă rugăm fierbinte, în Numele lui Hristos: Împăcaţi-vă cu Dumnezeu!” (2 Corinteni 5:18-20)" />.
      </p>
      <p>
        De asemenea, biserica împreună cu slujitorii ei înțelege rolul ei în lume și caută să se implice în ajutorarea celor în nevoi, îndeplinind ceea ce este scris: <BibleVerse reference="GAL.2.10" text="„Ne-au spus numai să ne aducem aminte de cei săraci, și chiar așa am și căutat să fac.” (Galateni 2:10)" />.
      </p>
    </div>
  )
}

export default WelcomeSection;
