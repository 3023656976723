import React from "react";
import Slider from "react-slick";
import HomePageSlide from "./HomePageSlide";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { romanianTexts } from "../utils/constants";

class HomePageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      padding: this.getPadding(),
      slides: [
        {
          title: romanianTexts.fullChurchName,
          subtitle:
            "Bine ați venit pe pagina oficială a Bisericii Betel din Sighetu Marmației!",
          imageUrl: "images/church_cropped.svg",
          internalLink: false,
          darkness: 50,
        },
        {
          type: "PROGRAME",
          title: "Va aștepăm la programele noastre",
          subtitle:
            "Aici puteți consulta programul slujbelor bisericii noastre.",
          imageUrl: "images/interior_front_empty.svg",
          internalLink: true,
          url: "/ministries",
          buttonText: "SLUJBELE BISERICII",
          darkness: 30,
        },
        // {
        //   "type": "EVENIMENTE SPECIALE",
        //   "title": "Vă așteptăm la programele de evanghelizare",
        //   "subtitle": "In fiecare duminică seara avem programe speciale de evanghelizare, de la ora 17.00.",
        //   "imageUrl": "images/interior_back_full.jpg",
        //   "internalLink": false
        // }
      ],
    };
  }

  getPadding = () => {
    return 0;
  };

  updateDimensions = () => {
    this.setState({
      padding: this.getPadding(),
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    var settings = {
      centerMode: true,
      centerPadding: this.state.padding + "px",
      slidesToShow: 1,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 5000,
      dots: true,
      adaptiveHeight: true,
    };

    const slides = this.state.slides.map((slide) => {
      return (
        <div key={JSON.stringify(slide)}>
          <HomePageSlide {...slide} />
        </div>
      );
    });

    return <Slider {...settings}>{slides}</Slider>;
  }
}

export default HomePageSlider;
