import React from 'react';
import Page from './page/Page';
import PageBanner from './page/PageBanner';

const NotFoundPage = () => (
    <Page>
        <PageBanner
            videoUrl="/images/not_found/404.mp4"
            videoAltText="Page Not Found! Click here to return to the main page."
            linkUrl="/"
        />
    </Page>
);

export default NotFoundPage;