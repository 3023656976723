import React from 'react';
import Page from './page/Page';
import PageBanner from './page/PageBanner';

class MinistriesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "ÎNTÂLNIRILE NOASTRE"
    }
  }

  render() {
    return (
      <Page>
        <PageBanner title={this.state.title} imageUrl={this.state.currentBannerImage} bannerMobilePosition={this.state.bannerMobilePosition} titleColor="#EDE5E1" />
        <div className="schedule">
          <img className="image" src="/logos/schedule.png" alt="" />
          <div>
            {/* <div className="red-content">
              <h2>Program special pentru perioada stării de alertă</h2>
              <table className="table" >
                <tbody>
                  <tr><td>Duminică</td><td>09:00 - 12:00</td><td>Rugăciune și vestirea Evangheliei</td></tr>
                  <tr><td></td><td>17:00 - 19:00</td><td>Rugăciune și vestirea Evangheliei</td></tr>
                  <tr><td>Luni</td><td>18:00 - 20:00</td><td>Întâlnire de tineret</td></tr>
                  <tr><td>Marți</td><td>18:00 - 20:00</td><td>Rugăciune</td></tr>
                  <tr><td>Joi</td><td>18:00 - 20:00</td><td>Rugăciune și vestirea Evangheliei</td></tr>
                </tbody>
              </table>
            </div>
            <hr /> */}
            <h2>Programul bisericii</h2>
            <table className="table" >
              <tbody>
                <tr><td>Duminică</td><td>09:00 - 12:00</td><td>Rugăciune și vestirea Evangheliei</td></tr>
                <tr><td></td><td>17:00 - 19:00</td><td>Rugăciune și vestirea Evangheliei</td></tr>
                <tr><td>Luni</td><td>18:00 - 20:00</td><td>Întâlnire de tineret</td></tr>
                <tr><td>Marți</td><td>18:00 - 20:00</td><td>Rugăciune</td></tr>
                <tr><td>Joi</td><td>18:00 - 20:00</td><td>Rugăciune și vestirea Evangheliei</td></tr>
                <tr><td>Vineri</td><td>18:00 - 20:00</td><td>Repetiție orchestră</td></tr>
              </tbody>
            </table>
            <br />
          </div>
        </div>
        <div className="observations">
          <h4>Observații</h4>
          <br />
          <ul>
            <li>În ultima vineri din fiecare lună avem o seară de rugăciune între orele 18:00 - 20:00.</li>
            <li>În prima duminică din fiecare lună, la serviciul de dimineață, sărbătorim Cina Domnului.</li>
          </ul>
        </div>
      </Page>
    )
  }
}

export default MinistriesPage;
