import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { churchLocation } from './utils/constants';
import { googleAPIKey, appleMapStyles } from './utils/googleConstants';

const Map = ({ fullWidth }) => {
  const MyMapComponent = withScriptjs(withGoogleMap(() =>
    <GoogleMap
      defaultZoom={17}
      defaultCenter={churchLocation}
      style={appleMapStyles}
    >
      <Marker position={{ lat: 54.454871, lng: -5.826396 }} title="Biserica Creștină Penticostală Betel" />
    </GoogleMap>
  ))

  var className = "";

  if (fullWidth) {
    className = "full-width-map"
  }

  return (
    <div className={className}>
      <MyMapComponent
        isMarkerShown
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  )
}

export default Map;
