import React, { useState } from 'react'
import { connect } from 'react-redux'
import AddVideoModal from './AddVideoModal'

export const VideosAdminHeader = ({ isAuthenticated }) => {
  const [isOpenModal, setOpenModal] = useState(false)

  if (isAuthenticated) {
    const onOpenModal = () => {
      setOpenModal(!isOpenModal)
    }

    return (
      <>
        <div className="page-header">
          <button className="button" onClick={onOpenModal}>Add Video</button>
        </div>
        <AddVideoModal open={isOpenModal} toggleModal={onOpenModal} />
      </>
    )
  } else {
    return
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid
})

export default connect(mapStateToProps)(VideosAdminHeader)
