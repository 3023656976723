import React from 'react';
import { Link } from 'react-router-dom';

const HomePageSlide = ({
  type,
  title,
  imageUrl,
  subtitle,
  url,
  subsubtitle,
  buttonText = "ACCESAȚI",
  target = "_self",
  internalLink = false,
  textColor = "#EDE5E1",
  fullHeightLink = false,
  backgroundPosition = "center",
  backgroundColor = "",
  darkness = 50
}) => {
  
  const slideStyle = (imageUrl) ? {
    background: `linear-gradient( rgba(0, 0, 0, ${darkness / 100}), rgba(0, 0, 0, ${darkness / 100}) ), url(${imageUrl})`,
    backgroundPosition
  } : {
    backgroundColor,
    backgroundPosition
  }

  const buttonWrapperStyle = {
    textAlign: "center"
  }

  let hrStyle = {
    color: "#EDE5E1", // white
    borderColor: "#EDE5E1", // white
    backgroundColor: "#EDE5E1" // white
  }

  if (textColor === "text-dark") {
    hrStyle = {
      color: "#474853", // black
      borderColor: "#474853", // black
      backgroundColor: "#474853" // black
    }
  }

  let lowerSection;

  if (url && internalLink && !fullHeightLink) {
    lowerSection = (
      <div style={buttonWrapperStyle}>
        <Link to={url} role="button" className={`btn btn-outline-primary image-slide-button center-block ${textColor}`}>
          {buttonText}
        </Link>
      </div>
    )
  } else if (url && !fullHeightLink) {
    lowerSection = (
      <div style={buttonWrapperStyle}>
        <a role="button" target={target} className={`btn btn-outline-primary image-slide-button center-block ${textColor}`} href={url}>{buttonText}</a>
      </div>
    )
  } else {
    lowerSection = (
      <div>
        <hr style={hrStyle} />
        <p className={`image-slide-sub-subtitle ${textColor}`}>{subsubtitle}</p>
      </div>
    )
  }

  const slideContents = (
    <div className="image-slide" style={slideStyle}>
      <div className="row image-slide-fullheight-row">
        <div className="col align-self-center image-slide-content">
          <p className={`image-slide-type ${textColor}`}>{type}</p>
          <hr style={hrStyle} />
          <p className={`image-slide-title ${textColor}`}>{title}</p>
          <p className={`image-slide-sub-title ${textColor}`}>{subtitle}</p>
          {lowerSection}
        </div>
      </div>
    </div>
  );

  if (fullHeightLink) {
    return (
      <a href={url} className="image-slide-link">
        {slideContents}
      </a>
    )
  } else {
    return slideContents;
  }

}

export default HomePageSlide;
