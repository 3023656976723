import React from 'react'

const BibleVerse = ({ reference, text }) => {

  const url = "https://www.bible.com/ro/bible/191/" + reference + ".VDC"

  return (
    <a className="bible-verse" href={url} target="_blank" rel="noopener noreferrer">{text}</a>
  )

}

export default BibleVerse
