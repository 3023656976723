import React from 'react';
import { Link } from 'react-router-dom';

const PageFragment = ({ title = "", text = "", buttonText = "", buttonUrl = "" }) => {
  return (
    <div className="page-fragment">
      {(title) ? <h2>{title}</h2> : ""}
      {(text) ? <div>{text}</div> : ""}
      {(buttonText && buttonUrl) ? (
        <Link to={buttonUrl} role="button" className={`btn btn-outline-primary our-button center-block`}>
          {buttonText}
        </Link>
      ) : ("")}
    </div>
  );
}

export default PageFragment;

// import React from 'react';
// import { Link } from 'react-router-dom';

// const PageFragment = ({
//   title = "",
//   text = "",
//   buttonText = "",
//   buttonUrl = "",
//   backgroundColor = "",
//   backgroundGradientColor = ""
// }) => {

//   let backgroundStyle = ""
//   if (backgroundColor) {
//     // if (backgroundGradientColor) {
//     //   backgroundStyle = {
//     //     backgroundImage: `linear-gradient(${backgroundColor}, ${backgroundGradientColor})`
//     //   }
//     // } else {
//       backgroundStyle = {
//         background: `${backgroundColor}`
//       }
//     // }
//   }

//   return (
//     <div className="page-fragment" style={backgroundStyle}>
//       {(title) ? <h2>{title}</h2> : ""}
//       {(text) ? <p>{text}</p> : ""}
//       {(buttonText && buttonUrl) ? (
//         <Link to={buttonUrl} role="button" className={`btn btn-outline-primary our-button center-block`}>
//           {buttonText}
//         </Link>
//       ) : ("")}
//     </div>
//   );
// }

// export default PageFragment;
