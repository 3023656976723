import React, { useState } from 'react'
import { connect } from 'react-redux'
import EditVideoModal from './EditVideoModal'
import PlayerModal from './PlayerModal'
import moment from 'moment'

// Export a stateless functional component
const VideoListItem = ({ isAuthenticated, id, title, url, createdAt }) => {
    const [isOpenPlayerModal, setOpenPlayerModal] = useState(false)
    const [isOpenEditModal, setOpenEditModal] = useState(false)

    const onOpenPlayerModal = () => {
        setOpenPlayerModal(!isOpenPlayerModal)
    }

    if (isAuthenticated) {
        const onOpenEditModal = () => {
            setOpenEditModal(!isOpenEditModal)
        }

        return (
            <>
                <div className="list-item">
                    <img className="list-item__thumbnail" src="/images/video_thumbnail.png" onClick={onOpenPlayerModal} alt="thumbnail" />
                    <div className="list-item__data" onClick={onOpenPlayerModal}>
                        <h3 className="list-item__title">{title}</h3>
                        <span className="list-item__sub-title">{moment(createdAt).format('MMMM Do, YYYY')}</span>
                    </div>
                    <button className="btn button-on-right" onClick={onOpenEditModal}>Edit</button>
                </div>
                <PlayerModal open={isOpenPlayerModal} toggleModal={onOpenPlayerModal} url={url} />
                <EditVideoModal open={isOpenEditModal} toggleModal={onOpenEditModal} videoId={id} />
            </>
        )
    } else {
        return (
            <>
                <div className="list-item" onClick={onOpenPlayerModal}>
                    <img className="list-item__thumbnail" src="/images/video_thumbnail.png" alt="thumbnail" />
                    <div className="list-item__data">
                        <h3 className="list-item__title">{title}</h3>
                        <span className="list-item__sub-title">{moment(createdAt).format('MMMM Do, YYYY')}</span>
                    </div>
                </div>
                <PlayerModal open={isOpenPlayerModal} toggleModal={onOpenPlayerModal} url={url} />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid
})

export default connect(mapStateToProps)(VideoListItem);
