import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { startAddVideo } from "../../redux-store/actions/videos";
import { startEditLive } from "../../redux-store/actions/live";
import Switch from "../utils/Switch";
import VideoForm from "./VideoForm";

const AddVideoModal = ({ open, toggleModal, startAddVideo, startEditLive }) => {
  const [doUpdateLiveUrl, toggleDoUpdateLiveUrl] = useState(false)

  const onSubmit = (video) => {
    startAddVideo(video)

    if (doUpdateLiveUrl) {
      startEditLive(video.url)
    }

    toggleModal()
  };

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      styles={{
        modal: {
          maxWidth: "unset",
          width: "70%",
          padding: "unset",
          border: 0,
          background: "#EDE5E1"
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)"
        }
      }}
      center
    >
      <div>
        <div className="page-header">
          <div className="content-container">
            <h1 className="page-header__title">Add Video</h1>
          </div>
        </div>
        <div className="content-container">
          <VideoForm onSubmitCallback={onSubmit} />
          <Switch
            isOn={doUpdateLiveUrl}
            onColor="#8E8268"
            handleToggle={() => toggleDoUpdateLiveUrl(!doUpdateLiveUrl)}
            description="Update live URL"
          />
        </div>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => ({
  startAddVideo: (video) => dispatch(startAddVideo(video)),
  startEditLive: (videoUrl) => dispatch(startEditLive({ videoUrl }))
});

export default connect(undefined, mapDispatchToProps)(AddVideoModal);
