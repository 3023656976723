import React from 'react';
import { connect } from 'react-redux';
import VideoListItem from './VideoListItem';
import selectVideos from '../../redux-store/selectors/videos';
import { startSetVideos } from '../../redux-store/actions/videos';
// import LoadingPage from '../LoadingPage';

export const VideoList = (props) => {
    // const [isLoading, toggleIsLoading] = useState(true)

    // useEffect(() => {   // componentDidMount
    //     props.startSetVideos().then(() => {
    //         toggleIsLoading(false)
    //     })
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // if (isLoading) {
    //     return (
    //         <LoadingPage />
    //     )
    // }

    return (
        <>
            <div className="list-header">
                <div>Programele filtrate</div>
            </div>
            <div className="list-body">
                {
                    props.videos.length === 0 ? (
                        <div className="list-item list-item--message">
                            <span>Nu există video-uri</span>
                        </div>
                    ) : (
                            props.videos.map((video) => (<VideoListItem className="list-item" key={video.id} {...video} />))
                        )
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    videos: selectVideos(state.videos, state.filters)
});

const mapDispatchToProps = (dispatch) => ({
    startSetVideos: async () => dispatch(startSetVideos())
});

// Connect Video List
export default connect(mapStateToProps, mapDispatchToProps)(VideoList);
