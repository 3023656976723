import React from 'react'
import { connect } from 'react-redux';
import selectVideos from '../../redux-store/selectors/videos';

export const VideosSummary = ({ videosCount, videosTotal }) => {
  const videoWord = videosCount === 1 ? 'video selectat' : 'video-uri selectate'
  return (
    <div className="page-header">
      <p className="page-header__title"><span>{videosCount}</span> {videoWord} din <span>{videosTotal}</span></p>
    </div>
  )
};

const mapStateToProps = (state) => {
  const visibleVideos = selectVideos(state.videos, state.filters);

  return {
    videosCount: visibleVideos.length,
    videosTotal: state.videos.length
  };
};

export default connect(mapStateToProps)(VideosSummary);