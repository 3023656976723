import React, { useState } from 'react'
import { connect } from 'react-redux';
import EditLiveModal from './EditLiveModal';
import Switch from '../utils/Switch';
import { startToggleShowLive } from '../../redux-store/actions/live';

export const LiveAdminHeader = ({ isAuthenticated, live, startToggleShowLive }) => {
  const [isOpenModal, setOpenModal] = useState(false)

  if (isAuthenticated) {
    const onOpenModal = () => {
      setOpenModal(!isOpenModal)
    }

    return (
      <>
        <div className="page-header">
          <Switch
            isOn={live.showLive}
            onColor="#8E8268"
            handleToggle={() => startToggleShowLive(!live.showLive)}
            description="Show Live"
          />
          <button className="button" onClick={onOpenModal}>Edit Live</button>
        </div>
        <EditLiveModal open={isOpenModal} toggleModal={onOpenModal} />
      </>
    )
  } else {
    return
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid,
  live: state.live
})

const mapDispatchToProps = (dispatch) => ({
  startToggleShowLive: (showLive) => dispatch(startToggleShowLive(showLive))
})

export default connect(mapStateToProps, mapDispatchToProps)(LiveAdminHeader)
