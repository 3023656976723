import * as firebase from 'firebase'
import * as firebaseui from 'firebaseui'

// App's Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
}

// Initialize Firebase.
firebase.initializeApp(firebaseConfig)

// To apply the default browser preference instead of explicitly setting it.
firebase.auth().useDeviceLanguage();

const database = firebase.database()
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

export { firebase, firebaseui, googleAuthProvider, database as default }

// firebase.database().ref().set({
//   name: 'Samuel Bumbar'
// })

// // child_removed
// database.ref('videos').on('child_removed', (snapshot) => {
//   console.log(snapshot.key, snapshot.val());
// });

// // child_changed
// database.ref('videos').on('child_changed', (snapshot) => {
//   console.log(snapshot.key, snapshot.val());
// });

// // child_added
// database.ref('videos').on('child_added', (snapshot) => {
//   console.log(snapshot.key, snapshot.val());
// });